@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
b {
	font-weight: 600;
}

strong {
	font-weight: 700;
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-pewter-blue: #809fb8;
	--bs-tertiary: #fc5000;
	--bs-gray: #707070;
	--bs-disabled: #ababac;
	--bs-primary: #043087;
	--bs-secondary: #1c99ff;
	--bs-success: #00b140;
	--bs-info: #0dcaf0;
	--bs-warning: #e2b93b;
	--bs-danger: #fc0000;
	--bs-light: #f8f9fa;
	--bs-dark: #262626;
	--bs-pewter-blue-rgb: 128, 159, 184;
	--bs-tertiary-rgb: 252, 80, 0;
	--bs-gray-rgb: 112, 112, 112;
	--bs-disabled-rgb: 171, 171, 172;
	--bs-primary-rgb: 4, 48, 135;
	--bs-secondary-rgb: 28, 153, 255;
	--bs-success-rgb: 0, 177, 64;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 226, 185, 59;
	--bs-danger-rgb: 252, 0, 0;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 38, 38, 38;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-color-rgb: 33, 37, 41;
	--bs-body-bg-rgb: 255, 255, 255;
	--bs-font-sans-serif: "Poppins", sans-serif;
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.15),
		rgba(255, 255, 255, 0)
	);
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #212529;
	--bs-body-bg: #fff;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}

body {
	margin: 0;
	font-family: var(--bs-body-font-family);
	font-size: var(--bs-body-font-size);
	font-weight: var(--bs-body-font-weight);
	line-height: var(--bs-body-line-height);
	color: var(--bs-body-color);
	text-align: var(--bs-body-text-align);
	background-color: var(--bs-body-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.25;
}

hr:not([size]) {
	height: 1px;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 600;
	line-height: 1.2;
}

h1,
.h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	h1,
	.h1 {
		font-size: 2.5rem;
	}
}

h2,
.h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	h2,
	.h2 {
		font-size: 2rem;
	}
}

h3,
.h3 {
	font-size: calc(1.29375rem + 0.525vw);
}
@media (min-width: 1200px) {
	h3,
	.h3 {
		font-size: 1.6875rem;
	}
}

h4,
.h4 {
	font-size: 1rem;
}

h5,
.h5 {
	font-size: 1.25rem;
}

h6,
.h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
	text-decoration: underline dotted;
	cursor: help;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul {
	padding-left: 2rem;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small,
.small {
	font-size: 0.875em;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #043087;
	text-decoration: underline;
}
a:hover {
	color: #03266c;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr /* rtl:ignore */;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
}
pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

code {
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
}
a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 0.875em;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}
kbd kbd {
	padding: 0;
	font-size: 1em;
	font-weight: 700;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #6c757d;
	text-align: left;
}

th {
	font-weight: 600;
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role="button"] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}
select:disabled {
	opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

textarea {
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}
@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem;
	}
}
legend + * {
	clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::file-selector-button {
	font: inherit;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}

.display-1 {
	font-size: calc(1.625rem + 4.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-1 {
		font-size: 5rem;
	}
}

.display-2 {
	font-size: calc(1.575rem + 3.9vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-2 {
		font-size: 4.5rem;
	}
}

.display-3 {
	font-size: calc(1.525rem + 3.3vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-3 {
		font-size: 4rem;
	}
}

.display-4 {
	font-size: calc(1.475rem + 2.7vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-4 {
		font-size: 3.5rem;
	}
}

.display-5 {
	font-size: calc(1.425rem + 2.1vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-5 {
		font-size: 3rem;
	}
}

.display-6 {
	font-size: calc(1.375rem + 1.5vw);
	font-weight: 300;
	line-height: 1.2;
}
@media (min-width: 1200px) {
	.display-6 {
		font-size: 2.5rem;
	}
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}
.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

.initialism {
	font-size: 0.875em;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}
.blockquote > :last-child {
	margin-bottom: 0;
}

.blockquote-footer {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 0.875em;
	color: #6c757d;
}
.blockquote-footer::before {
	content: "— ";
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 0.875em;
	color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container-sm,
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container-md,
	.container-sm,
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1140px;
	}
}
@media (min-width: 1400px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1320px;
	}
}
.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-0.5 * var(--bs-gutter-x));
	margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0%;
}

.row-cols-auto > * {
	flex: 0 0 auto;
	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;
	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;
	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;
	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;
	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.33333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.66666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.33333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.66666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.33333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.66666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.33333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.66666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.offset-1 {
	margin-left: 8.33333333%;
}

.offset-2 {
	margin-left: 16.66666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.33333333%;
}

.offset-5 {
	margin-left: 41.66666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.33333333%;
}

.offset-8 {
	margin-left: 66.66666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.33333333%;
}

.offset-11 {
	margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0;
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0;
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
	--bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
	--bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
	--bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
	--bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
	.col-sm {
		flex: 1 0 0%;
	}

	.row-cols-sm-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-sm-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-sm-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-sm-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-sm-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-sm-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-sm-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-sm-0 {
		margin-left: 0;
	}

	.offset-sm-1 {
		margin-left: 8.33333333%;
	}

	.offset-sm-2 {
		margin-left: 16.66666667%;
	}

	.offset-sm-3 {
		margin-left: 25%;
	}

	.offset-sm-4 {
		margin-left: 33.33333333%;
	}

	.offset-sm-5 {
		margin-left: 41.66666667%;
	}

	.offset-sm-6 {
		margin-left: 50%;
	}

	.offset-sm-7 {
		margin-left: 58.33333333%;
	}

	.offset-sm-8 {
		margin-left: 66.66666667%;
	}

	.offset-sm-9 {
		margin-left: 75%;
	}

	.offset-sm-10 {
		margin-left: 83.33333333%;
	}

	.offset-sm-11 {
		margin-left: 91.66666667%;
	}

	.g-sm-0,
	.gx-sm-0 {
		--bs-gutter-x: 0;
	}

	.g-sm-0,
	.gy-sm-0 {
		--bs-gutter-y: 0;
	}

	.g-sm-1,
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem;
	}

	.g-sm-1,
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem;
	}

	.g-sm-2,
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem;
	}

	.g-sm-2,
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem;
	}

	.g-sm-3,
	.gx-sm-3 {
		--bs-gutter-x: 1rem;
	}

	.g-sm-3,
	.gy-sm-3 {
		--bs-gutter-y: 1rem;
	}

	.g-sm-4,
	.gx-sm-4 {
		--bs-gutter-x: 1.5rem;
	}

	.g-sm-4,
	.gy-sm-4 {
		--bs-gutter-y: 1.5rem;
	}

	.g-sm-5,
	.gx-sm-5 {
		--bs-gutter-x: 3rem;
	}

	.g-sm-5,
	.gy-sm-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0%;
	}

	.row-cols-md-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-md-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-md-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-md-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-md-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-md-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-md-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-md-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-md-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-md-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-md-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-md-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-md-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-md-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-md-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-md-0 {
		margin-left: 0;
	}

	.offset-md-1 {
		margin-left: 8.33333333%;
	}

	.offset-md-2 {
		margin-left: 16.66666667%;
	}

	.offset-md-3 {
		margin-left: 25%;
	}

	.offset-md-4 {
		margin-left: 33.33333333%;
	}

	.offset-md-5 {
		margin-left: 41.66666667%;
	}

	.offset-md-6 {
		margin-left: 50%;
	}

	.offset-md-7 {
		margin-left: 58.33333333%;
	}

	.offset-md-8 {
		margin-left: 66.66666667%;
	}

	.offset-md-9 {
		margin-left: 75%;
	}

	.offset-md-10 {
		margin-left: 83.33333333%;
	}

	.offset-md-11 {
		margin-left: 91.66666667%;
	}

	.g-md-0,
	.gx-md-0 {
		--bs-gutter-x: 0;
	}

	.g-md-0,
	.gy-md-0 {
		--bs-gutter-y: 0;
	}

	.g-md-1,
	.gx-md-1 {
		--bs-gutter-x: 0.25rem;
	}

	.g-md-1,
	.gy-md-1 {
		--bs-gutter-y: 0.25rem;
	}

	.g-md-2,
	.gx-md-2 {
		--bs-gutter-x: 0.5rem;
	}

	.g-md-2,
	.gy-md-2 {
		--bs-gutter-y: 0.5rem;
	}

	.g-md-3,
	.gx-md-3 {
		--bs-gutter-x: 1rem;
	}

	.g-md-3,
	.gy-md-3 {
		--bs-gutter-y: 1rem;
	}

	.g-md-4,
	.gx-md-4 {
		--bs-gutter-x: 1.5rem;
	}

	.g-md-4,
	.gy-md-4 {
		--bs-gutter-y: 1.5rem;
	}

	.g-md-5,
	.gx-md-5 {
		--bs-gutter-x: 3rem;
	}

	.g-md-5,
	.gy-md-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0%;
	}

	.row-cols-lg-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-lg-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-lg-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-lg-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-lg-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-lg-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-lg-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-lg-0 {
		margin-left: 0;
	}

	.offset-lg-1 {
		margin-left: 8.33333333%;
	}

	.offset-lg-2 {
		margin-left: 16.66666667%;
	}

	.offset-lg-3 {
		margin-left: 25%;
	}

	.offset-lg-4 {
		margin-left: 33.33333333%;
	}

	.offset-lg-5 {
		margin-left: 41.66666667%;
	}

	.offset-lg-6 {
		margin-left: 50%;
	}

	.offset-lg-7 {
		margin-left: 58.33333333%;
	}

	.offset-lg-8 {
		margin-left: 66.66666667%;
	}

	.offset-lg-9 {
		margin-left: 75%;
	}

	.offset-lg-10 {
		margin-left: 83.33333333%;
	}

	.offset-lg-11 {
		margin-left: 91.66666667%;
	}

	.g-lg-0,
	.gx-lg-0 {
		--bs-gutter-x: 0;
	}

	.g-lg-0,
	.gy-lg-0 {
		--bs-gutter-y: 0;
	}

	.g-lg-1,
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem;
	}

	.g-lg-1,
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem;
	}

	.g-lg-2,
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem;
	}

	.g-lg-2,
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem;
	}

	.g-lg-3,
	.gx-lg-3 {
		--bs-gutter-x: 1rem;
	}

	.g-lg-3,
	.gy-lg-3 {
		--bs-gutter-y: 1rem;
	}

	.g-lg-4,
	.gx-lg-4 {
		--bs-gutter-x: 1.5rem;
	}

	.g-lg-4,
	.gy-lg-4 {
		--bs-gutter-y: 1.5rem;
	}

	.g-lg-5,
	.gx-lg-5 {
		--bs-gutter-x: 3rem;
	}

	.g-lg-5,
	.gy-lg-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 1200px) {
	.col-xl {
		flex: 1 0 0%;
	}

	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-xl-0 {
		margin-left: 0;
	}

	.offset-xl-1 {
		margin-left: 8.33333333%;
	}

	.offset-xl-2 {
		margin-left: 16.66666667%;
	}

	.offset-xl-3 {
		margin-left: 25%;
	}

	.offset-xl-4 {
		margin-left: 33.33333333%;
	}

	.offset-xl-5 {
		margin-left: 41.66666667%;
	}

	.offset-xl-6 {
		margin-left: 50%;
	}

	.offset-xl-7 {
		margin-left: 58.33333333%;
	}

	.offset-xl-8 {
		margin-left: 66.66666667%;
	}

	.offset-xl-9 {
		margin-left: 75%;
	}

	.offset-xl-10 {
		margin-left: 83.33333333%;
	}

	.offset-xl-11 {
		margin-left: 91.66666667%;
	}

	.g-xl-0,
	.gx-xl-0 {
		--bs-gutter-x: 0;
	}

	.g-xl-0,
	.gy-xl-0 {
		--bs-gutter-y: 0;
	}

	.g-xl-1,
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem;
	}

	.g-xl-1,
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem;
	}

	.g-xl-2,
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem;
	}

	.g-xl-2,
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem;
	}

	.g-xl-3,
	.gx-xl-3 {
		--bs-gutter-x: 1rem;
	}

	.g-xl-3,
	.gy-xl-3 {
		--bs-gutter-y: 1rem;
	}

	.g-xl-4,
	.gx-xl-4 {
		--bs-gutter-x: 1.5rem;
	}

	.g-xl-4,
	.gy-xl-4 {
		--bs-gutter-y: 1.5rem;
	}

	.g-xl-5,
	.gx-xl-5 {
		--bs-gutter-x: 3rem;
	}

	.g-xl-5,
	.gy-xl-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0%;
	}

	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}

	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}

	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}

	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}

	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}

	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}

	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}

	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}

	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}

	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}

	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}

	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}

	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}

	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}

	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}

	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}

	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}

	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}

	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}

	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}

	.offset-xxl-0 {
		margin-left: 0;
	}

	.offset-xxl-1 {
		margin-left: 8.33333333%;
	}

	.offset-xxl-2 {
		margin-left: 16.66666667%;
	}

	.offset-xxl-3 {
		margin-left: 25%;
	}

	.offset-xxl-4 {
		margin-left: 33.33333333%;
	}

	.offset-xxl-5 {
		margin-left: 41.66666667%;
	}

	.offset-xxl-6 {
		margin-left: 50%;
	}

	.offset-xxl-7 {
		margin-left: 58.33333333%;
	}

	.offset-xxl-8 {
		margin-left: 66.66666667%;
	}

	.offset-xxl-9 {
		margin-left: 75%;
	}

	.offset-xxl-10 {
		margin-left: 83.33333333%;
	}

	.offset-xxl-11 {
		margin-left: 91.66666667%;
	}

	.g-xxl-0,
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}

	.g-xxl-0,
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}

	.g-xxl-1,
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}

	.g-xxl-1,
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}

	.g-xxl-2,
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}

	.g-xxl-2,
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}

	.g-xxl-3,
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}

	.g-xxl-3,
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}

	.g-xxl-4,
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}

	.g-xxl-4,
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}

	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}

	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
}
.table {
	--bs-table-bg: transparent;
	--bs-table-accent-bg: transparent;
	--bs-table-striped-color: #212529;
	--bs-table-striped-bg: #fbfbfd;
	--bs-table-active-color: #212529;
	--bs-table-active-bg: rgba(0, 0, 0, 0.1);
	--bs-table-hover-color: #212529;
	--bs-table-hover-bg: rgba(0, 0, 0, 0.075);
	width: 100%;
	margin-bottom: 1rem;
	color: #262626;
	vertical-align: top;
	border-color: #dee2e6;
}
.table > :not(caption) > * > * {
	padding: 0.75rem 0.5rem;
	background-color: var(--bs-table-bg);
	border-bottom-width: 1px;
	box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
	vertical-align: inherit;
}
.table > thead {
	vertical-align: bottom;
}
.table > :not(:first-child) {
	border-top: 2px solid #dbe1e8;
}

.caption-top {
	caption-side: top;
}

.table-sm > :not(caption) > * > * {
	padding: 0.625rem 0.625rem;
}

.table-bordered > :not(caption) > * {
	border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
	border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
	border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
	border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
	--bs-table-accent-bg: var(--bs-table-striped-bg);
	color: var(--bs-table-striped-color);
}

.table-active {
	--bs-table-accent-bg: var(--bs-table-active-bg);
	color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
	--bs-table-accent-bg: var(--bs-table-hover-bg);
	color: var(--bs-table-hover-color);
}

.table-primary {
	--bs-table-bg: #cdd6e7;
	--bs-table-striped-bg: #c3cbdb;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #b9c1d0;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bec6d6;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #b9c1d0;
}

.table-secondary {
	--bs-table-bg: #d2ebff;
	--bs-table-striped-bg: #c8dff2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #bdd4e6;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #c2d9ec;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #bdd4e6;
}

.table-success {
	--bs-table-bg: #ccefd9;
	--bs-table-striped-bg: #c2e3ce;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #b8d7c3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bdddc9;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #b8d7c3;
}

.table-info {
	--bs-table-bg: #cff4fc;
	--bs-table-striped-bg: #c5e8ef;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #badce3;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #bfe2e9;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #badce3;
}

.table-warning {
	--bs-table-bg: #f9f1d8;
	--bs-table-striped-bg: #ede5cd;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e0d9c2;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e6dfc8;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e0d9c2;
}

.table-danger {
	--bs-table-bg: #fecccc;
	--bs-table-striped-bg: #f1c2c2;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #e5b8b8;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #ebbdbd;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #e5b8b8;
}

.table-light {
	--bs-table-bg: #f8f9fa;
	--bs-table-striped-bg: #ecedee;
	--bs-table-striped-color: #000;
	--bs-table-active-bg: #dfe0e1;
	--bs-table-active-color: #000;
	--bs-table-hover-bg: #e5e6e7;
	--bs-table-hover-color: #000;
	color: #000;
	border-color: #dfe0e1;
}

.table-dark {
	--bs-table-bg: #262626;
	--bs-table-striped-bg: #313131;
	--bs-table-striped-color: #fff;
	--bs-table-active-bg: #3c3c3c;
	--bs-table-active-color: #fff;
	--bs-table-hover-bg: #363636;
	--bs-table-hover-color: #fff;
	color: #fff;
	border-color: #3c3c3c;
}

.table-responsive {
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
	.table-responsive-sm {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}
@media (max-width: 767.98px) {
	.table-responsive-md {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
@media (max-width: 991.98px) {
	.table-responsive-lg {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
@media (max-width: 1199.98px) {
	.table-responsive-xl {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
@media (max-width: 1399.98px) {
	.table-responsive-xxl {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
.form-label {
	margin-bottom: 0.5rem;
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.25rem;
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.875rem;
}

.form-text {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #6c757d;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: white;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	appearance: none;
	border-radius: 0.625rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}
.form-control[type="file"] {
	overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
	cursor: pointer;
}
.form-control:focus {
	color: #212529;
	background-color: #fff;
	border-color: #8eccff;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}
.form-control::-webkit-date-and-time-value {
	height: 1.5em;
}
.form-control::placeholder {
	color: #6c757d;
	opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
	background-color: #fdfdfd;
	border-color: #d7dee5;
	opacity: 1;
}
.form-control::file-selector-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	margin-inline-end: 0.75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-control::file-selector-button {
		transition: none;
	}
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
	background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
	padding: 0.375rem 0.75rem;
	margin: -0.375rem -0.75rem;
	margin-inline-end: 0.75rem;
	color: #212529;
	background-color: #e9ecef;
	pointer-events: none;
	border-color: inherit;
	border-style: solid;
	border-width: 0;
	border-inline-end-width: 1px;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-control::-webkit-file-upload-button {
		transition: none;
	}
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
	background-color: #dde0e3;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.375rem 0;
	margin-bottom: 0;
	line-height: 1.5;
	color: #212529;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.form-control-sm {
	min-height: calc(1.5em + 0.5rem + 2px);
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
	padding: 0.25rem 0.5rem;
	margin: -0.25rem -0.5rem;
	margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
	padding: 0.25rem 0.5rem;
	margin: -0.25rem -0.5rem;
	margin-inline-end: 0.5rem;
}

.form-control-lg {
	min-height: calc(1.5em + 1rem + 2px);
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
	padding: 0.5rem 1rem;
	margin: -0.5rem -1rem;
	margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
	padding: 0.5rem 1rem;
	margin: -0.5rem -1rem;
	margin-inline-end: 1rem;
}

textarea.form-control {
	min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
	min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
	min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
	width: 3rem;
	height: auto;
	padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
	cursor: pointer;
}
.form-control-color::-moz-color-swatch {
	height: 1.5em;
	border-radius: 0.625rem;
}
.form-control-color::-webkit-color-swatch {
	height: 1.5em;
	border-radius: 0.625rem;
}

.form-select {
	display: block;
	width: 100%;
	padding: 0.875rem 2.25rem 0.875rem 1rem;
	-moz-padding-start: calc(1rem - 3px);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #809fb8;
	background-color: #fff;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23809FB8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	border: 1px solid #809fb880;
	border-radius: 0.625rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-select {
		transition: none;
	}
}
.form-select:focus {
	border-color: #8298c3;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
	padding-right: 1rem;
	background-image: none;
}
.form-select:disabled {
	background-color: #e9ecef;
	border-color: #d7dee5;
}
.form-select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #809fb8;
}

.form-select-sm {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.form-select-lg {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}
.form-check .form-check-input {
	float: left;
	margin-left: -1.5em;
}

.form-check-input {
	width: 1rem;
	height: 1rem;
	margin-top: 0.25rem;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid #809fb8;
	appearance: none;
	color-adjust: exact;
}
.form-check-input[type="checkbox"] {
	border-radius: 3px;
}
.form-check-input[type="radio"] {
	border-radius: 50%;
}
.form-check-input:active {
	filter: brightness(90%);
}
.form-check-input:focus {
	border-color: #8eccff;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(28, 153, 255, 0.25);
}
.form-check-input:checked {
	background-color: #1c99ff;
	border-color: #1c99ff;
}
.form-check-input:checked[type="checkbox"] {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMjMgMTAuMTcxNUwxNi4xOTIyIDAuOTc4NTE2TDE3LjYwNzIgMi4zOTI1Mkw3LjAwMDIzIDEyLjk5OTVMMC42MzYyMyA2LjYzNTUyTDIuMDUwMjMgNS4yMjE1Mkw3LjAwMDIzIDEwLjE3MTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
}
.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxOCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuMDAwMjMgMTAuMTcxNUwxNi4xOTIyIDAuOTc4NTE2TDE3LjYwNzIgMi4zOTI1Mkw3LjAwMDIzIDEyLjk5OTVMMC42MzYyMyA2LjYzNTUyTDIuMDUwMjMgNS4yMjE1Mkw3LjAwMDIzIDEwLjE3MTVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K");
}
.form-check-input[type="checkbox"]:indeterminate {
	background-color: #1c99ff;
	border-color: #1c99ff;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDEwIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMCAySDBWMEgxMFYyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
}
.form-check-input:disabled {
	pointer-events: none;
	filter: none;
	opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
	opacity: 0.5;
}

.form-switch {
	padding-left: 2.5em;
}
.form-switch .form-check-input {
	width: 2.625rem;
	margin-left: -2.5em;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
	background-position: left center;
	border-radius: 2em;
	transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-switch .form-check-input {
		transition: none;
	}
}
.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
	background-position: right center;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
	display: inline-block;
	margin-right: 1rem;
}

.btn-check {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
	pointer-events: none;
	filter: none;
	opacity: 0.65;
}

.form-range {
	width: 100%;
	height: 1.5rem;
	padding: 0;
	background-color: transparent;
	appearance: none;
}
.form-range:focus {
	outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}
.form-range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}
.form-range::-moz-focus-outer {
	border: 0;
}
.form-range::-webkit-slider-thumb {
	width: 1rem;
	height: 1rem;
	margin-top: -0.25rem;
	background-color: #043087;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-webkit-slider-thumb {
		transition: none;
	}
}
.form-range::-webkit-slider-thumb:active {
	background-color: #b4c1db;
}
.form-range::-webkit-slider-runnable-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range::-moz-range-thumb {
	width: 1rem;
	height: 1rem;
	background-color: #043087;
	border: 0;
	border-radius: 1rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	appearance: none;
}
@media (prefers-reduced-motion: reduce) {
	.form-range::-moz-range-thumb {
		transition: none;
	}
}
.form-range::-moz-range-thumb:active {
	background-color: #b4c1db;
}
.form-range::-moz-range-track {
	width: 100%;
	height: 0.5rem;
	color: transparent;
	cursor: pointer;
	background-color: #dee2e6;
	border-color: transparent;
	border-radius: 1rem;
}
.form-range:disabled {
	pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
	background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
	background-color: #adb5bd;
}

.form-floating {
	position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
	height: calc(3.5rem + 2px);
	line-height: 1.25;
}
.form-floating > label {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	padding: 1rem 0.75rem;
	pointer-events: none;
	border: 1px solid transparent;
	transform-origin: 0 0;
	transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.form-floating > label {
		transition: none;
	}
}
.form-floating > .form-control {
	padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
	color: transparent;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-select {
	padding-top: 1.625rem;
	padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
	opacity: 0.65;
	transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
	z-index: 3;
}
.input-group .btn {
	position: relative;
	z-index: 2;
}
.input-group .btn:focus {
	z-index: 3;
}

.input-group-text {
	display: flex;
	align-items: center;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
	border-radius: 0.625rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
	padding-right: 3.25rem;
}

.input-group:not(.has-validation)
	> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group.has-validation
	> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
		.valid-feedback
	):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #00b140;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #000;
	background-color: rgba(0, 177, 64, 0.9);
	border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #00b140;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300B140' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
	border-color: #00b140;
	box-shadow: 0 0 0 0.25rem rgba(0, 177, 64, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
	border-color: #00b140;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23809FB8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300B140' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
	border-color: #00b140;
	box-shadow: 0 0 0 0.25rem rgba(0, 177, 64, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
	border-color: #00b140;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
	background-color: #00b140;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
	box-shadow: 0 0 0 0.25rem rgba(0, 177, 64, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: #00b140;
}

.form-check-inline .form-check-input ~ .valid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
	z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
	z-index: 3;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #fc0000;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.25rem 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	color: #000;
	background-color: rgba(252, 0, 0, 0.9);
	border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	border-color: #fc0000;
	padding-right: calc(1.5em + 0.75rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FC0000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FC0000' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.1875rem) center;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	border-color: #fc0000;
	box-shadow: 0 0 0 0.25rem rgba(252, 0, 0, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
	padding-right: calc(1.5em + 0.75rem);
	background-position: top calc(0.375em + 0.1875rem) right
		calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: #fc0000;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
	padding-right: 4.125rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23809FB8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FC0000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FC0000' stroke='none'/%3e%3c/svg%3e");
	background-position: right 0.75rem center, center right 2.25rem;
	background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
	border-color: #fc0000;
	box-shadow: 0 0 0 0.25rem rgba(252, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
	border-color: #fc0000;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
	background-color: #fc0000;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(252, 0, 0, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
	color: #fc0000;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
	margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
	z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
	z-index: 3;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.875rem 3.125rem;
	font-size: 1rem;
	border-radius: 0.625rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
.btn:hover {
	color: #212529;
}
.btn-check:focus + .btn,
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
	pointer-events: none;
	opacity: 0.65;
}

.btn-pewter-blue {
	color: #000;
	background-color: #809fb8;
	border-color: #809fb8;
}
.btn-pewter-blue:hover {
	color: #000;
	background-color: #93adc3;
	border-color: #8da9bf;
}
.btn-check:focus + .btn-pewter-blue,
.btn-pewter-blue:focus {
	color: #000;
	background-color: #93adc3;
	border-color: #8da9bf;
	box-shadow: 0 0 0 0.25rem rgba(109, 135, 156, 0.5);
}
.btn-check:checked + .btn-pewter-blue,
.btn-check:active + .btn-pewter-blue,
.btn-pewter-blue:active,
.btn-pewter-blue.active,
.show > .btn-pewter-blue.dropdown-toggle {
	color: #000;
	background-color: #99b2c6;
	border-color: #8da9bf;
}
.btn-check:checked + .btn-pewter-blue:focus,
.btn-check:active + .btn-pewter-blue:focus,
.btn-pewter-blue:active:focus,
.btn-pewter-blue.active:focus,
.show > .btn-pewter-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(109, 135, 156, 0.5);
}
.btn-pewter-blue:disabled,
.btn-pewter-blue.disabled {
	color: #000;
	background-color: #809fb8;
	border-color: #809fb8;
}

.btn-tertiary {
	color: #000;
	background-color: #fc5000;
	border-color: #fc5000;
}
.btn-tertiary:hover {
	color: #000;
	background-color: #fc6a26;
	border-color: #fc621a;
}
.btn-check:focus + .btn-tertiary,
.btn-tertiary:focus {
	color: #000;
	background-color: #fc6a26;
	border-color: #fc621a;
	box-shadow: 0 0 0 0.25rem rgba(214, 68, 0, 0.5);
}
.btn-check:checked + .btn-tertiary,
.btn-check:active + .btn-tertiary,
.btn-tertiary:active,
.btn-tertiary.active,
.show > .btn-tertiary.dropdown-toggle {
	color: #000;
	background-color: #fd7333;
	border-color: #fc621a;
}
.btn-check:checked + .btn-tertiary:focus,
.btn-check:active + .btn-tertiary:focus,
.btn-tertiary:active:focus,
.btn-tertiary.active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(214, 68, 0, 0.5);
}
.btn-tertiary:disabled,
.btn-tertiary.disabled {
	color: #000;
	background-color: #fc5000;
	border-color: #fc5000;
}

.btn-gray {
	color: #fff;
	background-color: #707070;
	border-color: #707070;
}
.btn-gray:hover {
	color: #fff;
	background-color: #5f5f5f;
	border-color: #5a5a5a;
}
.btn-check:focus + .btn-gray,
.btn-gray:focus {
	color: #fff;
	background-color: #5f5f5f;
	border-color: #5a5a5a;
	box-shadow: 0 0 0 0.25rem rgba(133, 133, 133, 0.5);
}
.btn-check:checked + .btn-gray,
.btn-check:active + .btn-gray,
.btn-gray:active,
.btn-gray.active,
.show > .btn-gray.dropdown-toggle {
	color: #fff;
	background-color: #5a5a5a;
	border-color: #545454;
}
.btn-check:checked + .btn-gray:focus,
.btn-check:active + .btn-gray:focus,
.btn-gray:active:focus,
.btn-gray.active:focus,
.show > .btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(133, 133, 133, 0.5);
}
.btn-gray:disabled,
.btn-gray.disabled {
	color: #fff;
	background-color: #707070;
	border-color: #707070;
}

.btn-disabled {
	color: #000;
	background-color: #ababac;
	border-color: #ababac;
}
.btn-disabled:hover {
	color: #000;
	background-color: #b8b8b8;
	border-color: #b3b3b4;
}
.btn-check:focus + .btn-disabled,
.btn-disabled:focus {
	color: #000;
	background-color: #b8b8b8;
	border-color: #b3b3b4;
	box-shadow: 0 0 0 0.25rem rgba(145, 145, 146, 0.5);
}
.btn-check:checked + .btn-disabled,
.btn-check:active + .btn-disabled,
.btn-disabled:active,
.btn-disabled.active,
.show > .btn-disabled.dropdown-toggle {
	color: #000;
	background-color: #bcbcbd;
	border-color: #b3b3b4;
}
.btn-check:checked + .btn-disabled:focus,
.btn-check:active + .btn-disabled:focus,
.btn-disabled:active:focus,
.btn-disabled.active:focus,
.show > .btn-disabled.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(145, 145, 146, 0.5);
}
.btn-disabled:disabled,
.btn-disabled.disabled {
	color: #000;
	background-color: #ababac;
	border-color: #ababac;
}

.btn-primary {
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}
.btn-primary:hover {
	color: #fff;
	background-color: #032973;
	border-color: #03266c;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
	color: #fff;
	background-color: #032973;
	border-color: #03266c;
	box-shadow: 0 0 0 0.25rem rgba(42, 79, 153, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #03266c;
	border-color: #032465;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(42, 79, 153, 0.5);
}
.btn-primary:disabled,
.btn-primary.disabled {
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}

.btn-secondary {
	color: #000;
	background-color: #1c99ff;
	border-color: #1c99ff;
}
.btn-secondary:hover {
	color: #000;
	background-color: #3ea8ff;
	border-color: #33a3ff;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
	color: #000;
	background-color: #3ea8ff;
	border-color: #33a3ff;
	box-shadow: 0 0 0 0.25rem rgba(24, 130, 217, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
	color: #000;
	background-color: #49adff;
	border-color: #33a3ff;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(24, 130, 217, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
	color: #000;
	background-color: #1c99ff;
	border-color: #1c99ff;
}

.btn-success {
	color: #000;
	background-color: #00b140;
	border-color: #00b140;
}
.btn-success:hover {
	color: #000;
	background-color: #26bd5d;
	border-color: #1ab953;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
	color: #000;
	background-color: #26bd5d;
	border-color: #1ab953;
	box-shadow: 0 0 0 0.25rem rgba(0, 150, 54, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
	color: #000;
	background-color: #33c166;
	border-color: #1ab953;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(0, 150, 54, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
	color: #000;
	background-color: #00b140;
	border-color: #00b140;
}

.btn-info {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}
.btn-info:hover {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
	color: #000;
	background-color: #31d2f2;
	border-color: #25cff2;
	box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
	color: #000;
	background-color: #3dd5f3;
	border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}

.btn-warning {
	color: #000;
	background-color: #e2b93b;
	border-color: #e2b93b;
}
.btn-warning:hover {
	color: #000;
	background-color: #e6c458;
	border-color: #e5c04f;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
	color: #000;
	background-color: #e6c458;
	border-color: #e5c04f;
	box-shadow: 0 0 0 0.25rem rgba(192, 157, 50, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
	color: #000;
	background-color: #e8c762;
	border-color: #e5c04f;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(192, 157, 50, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
	color: #000;
	background-color: #e2b93b;
	border-color: #e2b93b;
}

.btn-danger {
	color: #000;
	background-color: #fc0000;
	border-color: #fc0000;
}
.btn-danger:hover {
	color: #000;
	background-color: #fc2626;
	border-color: #fc1a1a;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
	color: #000;
	background-color: #fc2626;
	border-color: #fc1a1a;
	box-shadow: 0 0 0 0.25rem rgba(214, 0, 0, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
	color: #000;
	background-color: #fd3333;
	border-color: #fc1a1a;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(214, 0, 0, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
	color: #000;
	background-color: #fc0000;
	border-color: #fc0000;
}

.btn-light {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-light:hover {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
	box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
	color: #000;
	background-color: #f9fafb;
	border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-dark {
	color: #fff;
	background-color: #262626;
	border-color: #262626;
}
.btn-dark:hover {
	color: #fff;
	background-color: #202020;
	border-color: #1e1e1e;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
	color: #fff;
	background-color: #202020;
	border-color: #1e1e1e;
	box-shadow: 0 0 0 0.25rem rgba(71, 71, 71, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
	color: #fff;
	background-color: #1e1e1e;
	border-color: #1d1d1d;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(71, 71, 71, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
	color: #fff;
	background-color: #262626;
	border-color: #262626;
}

.btn-outline-pewter-blue {
	color: #809fb8;
	border-color: #809fb8;
}
.btn-outline-pewter-blue:hover {
	color: #000;
	background-color: #809fb8;
	border-color: #809fb8;
}
.btn-check:focus + .btn-outline-pewter-blue,
.btn-outline-pewter-blue:focus {
	box-shadow: 0 0 0 0.25rem rgba(128, 159, 184, 0.5);
}
.btn-check:checked + .btn-outline-pewter-blue,
.btn-check:active + .btn-outline-pewter-blue,
.btn-outline-pewter-blue:active,
.btn-outline-pewter-blue.active,
.btn-outline-pewter-blue.dropdown-toggle.show {
	color: #000;
	background-color: #809fb8;
	border-color: #809fb8;
}
.btn-check:checked + .btn-outline-pewter-blue:focus,
.btn-check:active + .btn-outline-pewter-blue:focus,
.btn-outline-pewter-blue:active:focus,
.btn-outline-pewter-blue.active:focus,
.btn-outline-pewter-blue.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(128, 159, 184, 0.5);
}
.btn-outline-pewter-blue:disabled,
.btn-outline-pewter-blue.disabled {
	color: #809fb8;
	background-color: transparent;
}

.btn-outline-tertiary {
	color: #fc5000;
	border-color: #fc5000;
}
.btn-outline-tertiary:hover {
	color: #000;
	background-color: #fc5000;
	border-color: #fc5000;
}
.btn-check:focus + .btn-outline-tertiary,
.btn-outline-tertiary:focus {
	box-shadow: 0 0 0 0.25rem rgba(252, 80, 0, 0.5);
}
.btn-check:checked + .btn-outline-tertiary,
.btn-check:active + .btn-outline-tertiary,
.btn-outline-tertiary:active,
.btn-outline-tertiary.active,
.btn-outline-tertiary.dropdown-toggle.show {
	color: #000;
	background-color: #fc5000;
	border-color: #fc5000;
}
.btn-check:checked + .btn-outline-tertiary:focus,
.btn-check:active + .btn-outline-tertiary:focus,
.btn-outline-tertiary:active:focus,
.btn-outline-tertiary.active:focus,
.btn-outline-tertiary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(252, 80, 0, 0.5);
}
.btn-outline-tertiary:disabled,
.btn-outline-tertiary.disabled {
	color: #fc5000;
	background-color: transparent;
}

.btn-outline-gray {
	color: #707070;
	border-color: #707070;
}
.btn-outline-gray:hover {
	color: #fff;
	background-color: #707070;
	border-color: #707070;
}
.btn-check:focus + .btn-outline-gray,
.btn-outline-gray:focus {
	box-shadow: 0 0 0 0.25rem rgba(112, 112, 112, 0.5);
}
.btn-check:checked + .btn-outline-gray,
.btn-check:active + .btn-outline-gray,
.btn-outline-gray:active,
.btn-outline-gray.active,
.btn-outline-gray.dropdown-toggle.show {
	color: #fff;
	background-color: #707070;
	border-color: #707070;
}
.btn-check:checked + .btn-outline-gray:focus,
.btn-check:active + .btn-outline-gray:focus,
.btn-outline-gray:active:focus,
.btn-outline-gray.active:focus,
.btn-outline-gray.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(112, 112, 112, 0.5);
}
.btn-outline-gray:disabled,
.btn-outline-gray.disabled {
	color: #707070;
	background-color: transparent;
}

.btn-outline-disabled {
	color: #ababac;
	border-color: #ababac;
}
.btn-outline-disabled:hover {
	color: #000;
	background-color: #ababac;
	border-color: #ababac;
}
.btn-check:focus + .btn-outline-disabled,
.btn-outline-disabled:focus {
	box-shadow: 0 0 0 0.25rem rgba(171, 171, 172, 0.5);
}
.btn-check:checked + .btn-outline-disabled,
.btn-check:active + .btn-outline-disabled,
.btn-outline-disabled:active,
.btn-outline-disabled.active,
.btn-outline-disabled.dropdown-toggle.show {
	color: #000;
	background-color: #ababac;
	border-color: #ababac;
}
.btn-check:checked + .btn-outline-disabled:focus,
.btn-check:active + .btn-outline-disabled:focus,
.btn-outline-disabled:active:focus,
.btn-outline-disabled.active:focus,
.btn-outline-disabled.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(171, 171, 172, 0.5);
}
.btn-outline-disabled:disabled,
.btn-outline-disabled.disabled {
	color: #ababac;
	background-color: transparent;
}

.btn-outline-primary {
	color: #043087;
	border-color: #043087;
}
.btn-outline-primary:hover {
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.5);
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
	color: #043087;
	background-color: transparent;
}

.btn-outline-secondary {
	color: #1c99ff;
	border-color: #1c99ff;
}
.btn-outline-secondary:hover {
	color: #000;
	background-color: #1c99ff;
	border-color: #1c99ff;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
	box-shadow: 0 0 0 0.25rem rgba(28, 153, 255, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
	color: #000;
	background-color: #1c99ff;
	border-color: #1c99ff;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(28, 153, 255, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
	color: #1c99ff;
	background-color: transparent;
}

.btn-outline-success {
	color: #00b140;
	border-color: #00b140;
}
.btn-outline-success:hover {
	color: #000;
	background-color: #00b140;
	border-color: #00b140;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
	box-shadow: 0 0 0 0.25rem rgba(0, 177, 64, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
	color: #000;
	background-color: #00b140;
	border-color: #00b140;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(0, 177, 64, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
	color: #00b140;
	background-color: transparent;
}

.btn-outline-info {
	color: #0dcaf0;
	border-color: #0dcaf0;
}
.btn-outline-info:hover {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
	box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
	color: #000;
	background-color: #0dcaf0;
	border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
	color: #0dcaf0;
	background-color: transparent;
}

.btn-outline-warning {
	color: #e2b93b;
	border-color: #e2b93b;
}
.btn-outline-warning:hover {
	color: #000;
	background-color: #e2b93b;
	border-color: #e2b93b;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
	box-shadow: 0 0 0 0.25rem rgba(226, 185, 59, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
	color: #000;
	background-color: #e2b93b;
	border-color: #e2b93b;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(226, 185, 59, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
	color: #e2b93b;
	background-color: transparent;
}

.btn-outline-danger {
	color: #fc0000;
	border-color: #fc0000;
}
.btn-outline-danger:hover {
	color: #000;
	background-color: #fc0000;
	border-color: #fc0000;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
	box-shadow: 0 0 0 0.25rem rgba(252, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
	color: #000;
	background-color: #fc0000;
	border-color: #fc0000;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(252, 0, 0, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
	color: #fc0000;
	background-color: transparent;
}

.btn-outline-light {
	color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-outline-light:hover {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
	box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
	color: #000;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
	color: #f8f9fa;
	background-color: transparent;
}

.btn-outline-dark {
	color: #262626;
	border-color: #262626;
}
.btn-outline-dark:hover {
	color: #fff;
	background-color: #262626;
	border-color: #262626;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
	box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
	color: #fff;
	background-color: #262626;
	border-color: #262626;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
	box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
	color: #262626;
	background-color: transparent;
}

.btn-link {
	font-weight: 400;
	color: #043087;
	text-decoration: underline;
}
.btn-link:hover {
	color: #03266c;
}
.btn-link:disabled,
.btn-link.disabled {
	color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 0.5rem 1.25rem;
	font-size: 0.875rem;
	border-radius: 0.5rem;
}

.fade {
	transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}
.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}
.collapsing.collapse-horizontal {
	width: 0;
	height: auto;
	transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
	.collapsing.collapse-horizontal {
		transition: none;
	}
}

.dropup,
.dropend,
.dropdown,
.dropstart {
	position: relative;
}

.dropdown-toggle {
	white-space: nowrap;
}
.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
	top: 100%;
	left: 0;
	margin-top: 0.125rem;
}

.dropdown-menu-start {
	--bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
	right: auto;
	left: 0;
}

.dropdown-menu-end {
	--bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
	right: 0;
	left: auto;
}

@media (min-width: 576px) {
	.dropdown-menu-sm-start {
		--bs-position: start;
	}
	.dropdown-menu-sm-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-sm-end {
		--bs-position: end;
	}
	.dropdown-menu-sm-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}
@media (min-width: 768px) {
	.dropdown-menu-md-start {
		--bs-position: start;
	}
	.dropdown-menu-md-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-md-end {
		--bs-position: end;
	}
	.dropdown-menu-md-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}
@media (min-width: 992px) {
	.dropdown-menu-lg-start {
		--bs-position: start;
	}
	.dropdown-menu-lg-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-lg-end {
		--bs-position: end;
	}
	.dropdown-menu-lg-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}
@media (min-width: 1200px) {
	.dropdown-menu-xl-start {
		--bs-position: start;
	}
	.dropdown-menu-xl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-xl-end {
		--bs-position: end;
	}
	.dropdown-menu-xl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}
@media (min-width: 1400px) {
	.dropdown-menu-xxl-start {
		--bs-position: start;
	}
	.dropdown-menu-xxl-start[data-bs-popper] {
		right: auto;
		left: 0;
	}

	.dropdown-menu-xxl-end {
		--bs-position: end;
	}
	.dropdown-menu-xxl-end[data-bs-popper] {
		right: 0;
		left: auto;
	}
}
.dropup .dropdown-menu[data-bs-popper] {
	top: auto;
	bottom: 100%;
	margin-top: 0;
	margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0;
	border-right: 0.3em solid transparent;
	border-bottom: 0.3em solid;
	border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
	margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
	top: 0;
	right: auto;
	left: 100%;
	margin-top: 0;
	margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0;
	border-bottom: 0.3em solid transparent;
	border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropend .dropdown-toggle::after {
	vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
	top: 0;
	right: 100%;
	left: auto;
	margin-top: 0;
	margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
}
.dropstart .dropdown-toggle::after {
	display: none;
}
.dropstart .dropdown-toggle::before {
	display: inline-block;
	margin-right: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid transparent;
	border-right: 0.3em solid;
	border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
	margin-left: 0;
}
.dropstart .dropdown-toggle::before {
	vertical-align: 0;
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	text-decoration: none;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #1e2125;
	background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: #043087;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #adb5bd;
	pointer-events: none;
	background-color: transparent;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #6c757d;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1rem;
	color: #212529;
}

.dropdown-menu-dark {
	color: #dee2e6;
	background-color: #343a40;
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
	color: #fff;
	background-color: #043087;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
	color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
	border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
	color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
	color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
	position: relative;
	flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
	z-index: 1;
}

.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.btn-toolbar .input-group {
	width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
	margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
	padding-right: 2.34375rem;
	padding-left: 2.34375rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
	margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
	margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
	padding-right: 0.9375rem;
	padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
}

.btn-group-vertical {
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
	width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
	margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #809fb8;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.nav-link {
		transition: none;
	}
}
.nav-link:hover,
.nav-link:focus {
	color: #043087;
}
.nav-link.disabled {
	color: #6c757d;
	pointer-events: none;
	cursor: default;
}

.nav-tabs {
	border-bottom: 1px solid #bfcfdb;
}
.nav-tabs .nav-link {
	margin-bottom: -1px;
	background: none;
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
	isolation: isolate;
}
.nav-tabs .nav-link.disabled {
	color: #6c757d;
	background-color: transparent;
	border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #043087;
	background-color: #fff;
	border-color: #fff #fff #043087;
}
.nav-tabs .dropdown-menu {
	margin-top: -1px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-pills .nav-link {
	background: none;
	border: 0;
	border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #043087;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
	flex: 1 1 auto;
	text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
	flex-basis: 0;
	flex-grow: 1;
	text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
	width: 100%;
}

.tab-content > .tab-pane {
	display: none;
}
.tab-content > .active {
	display: block;
}
.tab-content {
	padding: 0;
}

.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}
.navbar-brand {
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	text-decoration: none;
	white-space: nowrap;
}
.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-nav .dropdown-menu {
	position: static;
}

.navbar-text {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	background-color: transparent;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.navbar-toggler {
		transition: none;
	}
}
.navbar-toggler:hover {
	text-decoration: none;
}
.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.navbar-nav-scroll {
	max-height: var(--bs-scroll-height, 75vh);
	overflow-y: auto;
}

@media (min-width: 576px) {
	.navbar-expand-sm {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-sm .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-sm .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-sm .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-sm .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-sm .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-sm .navbar-toggler {
		display: none;
	}
	.navbar-expand-sm .offcanvas-header {
		display: none;
	}
	.navbar-expand-sm .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-sm .offcanvas-top,
	.navbar-expand-sm .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-sm .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}
@media (min-width: 768px) {
	.navbar-expand-md {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-md .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-md .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-md .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-md .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-md .navbar-toggler {
		display: none;
	}
	.navbar-expand-md .offcanvas-header {
		display: none;
	}
	.navbar-expand-md .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-md .offcanvas-top,
	.navbar-expand-md .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-md .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}
@media (min-width: 992px) {
	.navbar-expand-lg {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-lg .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-lg .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-lg .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-toggler {
		display: none;
	}
	.navbar-expand-lg .offcanvas-header {
		display: none;
	}
	.navbar-expand-lg .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-lg .offcanvas-top,
	.navbar-expand-lg .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-lg .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}
@media (min-width: 1200px) {
	.navbar-expand-xl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-xl .offcanvas-top,
	.navbar-expand-xl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}
@media (min-width: 1400px) {
	.navbar-expand-xxl {
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-xxl .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-xxl .navbar-nav .dropdown-menu {
		position: absolute;
	}
	.navbar-expand-xxl .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-xxl .navbar-nav-scroll {
		overflow: visible;
	}
	.navbar-expand-xxl .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
	.navbar-expand-xxl .navbar-toggler {
		display: none;
	}
	.navbar-expand-xxl .offcanvas-header {
		display: none;
	}
	.navbar-expand-xxl .offcanvas {
		position: inherit;
		bottom: 0;
		z-index: 1000;
		flex-grow: 1;
		visibility: visible !important;
		background-color: transparent;
		border-right: 0;
		border-left: 0;
		transition: none;
		transform: none;
	}
	.navbar-expand-xxl .offcanvas-top,
	.navbar-expand-xxl .offcanvas-bottom {
		height: auto;
		border-top: 0;
		border-bottom: 0;
	}
	.navbar-expand-xxl .offcanvas-body {
		display: flex;
		flex-grow: 0;
		padding: 0;
		overflow-y: visible;
	}
}
.navbar-expand {
	flex-wrap: nowrap;
	justify-content: flex-start;
}
.navbar-expand .navbar-nav {
	flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
	position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
	overflow: visible;
}
.navbar-expand .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
}
.navbar-expand .navbar-toggler {
	display: none;
}
.navbar-expand .offcanvas-header {
	display: none;
}
.navbar-expand .offcanvas {
	position: inherit;
	bottom: 0;
	z-index: 1000;
	flex-grow: 1;
	visibility: visible !important;
	background-color: transparent;
	border-right: 0;
	border-left: 0;
	transition: none;
	transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
	height: auto;
	border-top: 0;
	border-bottom: 0;
}
.navbar-expand .offcanvas-body {
	display: flex;
	flex-grow: 0;
	padding: 0;
	overflow-y: visible;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
	color: rgba(0, 0, 0, 0.55);
	border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
	color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
	color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
	color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}
.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.55);
	border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
	color: #fff;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.625rem;
}
.card > hr {
	margin-right: 0;
	margin-left: 0;
}
.card > .list-group {
	border-top: inherit;
	border-bottom: inherit;
}
.card > .list-group:first-child {
	border-top-width: 0;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
	border-bottom-width: 0;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
	border-top: 0;
}

.card-body {
	flex: 1 1 auto;
	padding: 1rem 1rem;
}

.card-title {
	margin-bottom: 0.5rem;
}

.card-subtitle {
	margin-top: -0.25rem;
	margin-bottom: 0;
}

.card-text:last-child {
	margin-bottom: 0;
}

.card-link + .card-link {
	margin-left: 1rem;
}

.card-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	background-color: rgba(0, 0, 0, 0.03);
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
	padding: 0.5rem 1rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
	margin-left: -0.5rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
}

.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1rem;
	border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
	width: 100%;
}

.card-img,
.card-img-top {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
	margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
	.card-group {
		display: flex;
		flex-flow: row wrap;
	}
	.card-group > .card {
		flex: 1 0 0%;
		margin-bottom: 0;
	}
	.card-group > .card + .card {
		margin-left: 0;
		border-left: 0;
	}
	.card-group > .card:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-top,
	.card-group > .card:not(:last-child) .card-header {
		border-top-right-radius: 0;
	}
	.card-group > .card:not(:last-child) .card-img-bottom,
	.card-group > .card:not(:last-child) .card-footer {
		border-bottom-right-radius: 0;
	}
	.card-group > .card:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-top,
	.card-group > .card:not(:first-child) .card-header {
		border-top-left-radius: 0;
	}
	.card-group > .card:not(:first-child) .card-img-bottom,
	.card-group > .card:not(:first-child) .card-footer {
		border-bottom-left-radius: 0;
	}
}

.accordion-button {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button {
		transition: none;
	}
}
.accordion-button:not(.collapsed) {
	color: #042b7a;
	background-color: #e6eaf3;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23042b7a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	transform: rotate(-180deg);
}
.accordion-button::after {
	flex-shrink: 0;
	width: 1.25rem;
	height: 1.25rem;
	margin-left: auto;
	content: "";
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-size: 1.25rem;
	transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.accordion-button::after {
		transition: none;
	}
}
.accordion-button:hover {
	z-index: 2;
}
.accordion-button:focus {
	z-index: 3;
	border-color: #8298c3;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}

.accordion-header {
	margin-bottom: 0;
}

.accordion-item {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
	border-top: 0;
}
.accordion-item:last-of-type {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.accordion-body {
	padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
	border-width: 0;
}
.accordion-flush .accordion-item {
	border-right: 0;
	border-left: 0;
	border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
	border-top: 0;
}
.accordion-flush .accordion-item:last-child {
	border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
	border-radius: 0;
}

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0;
	margin-bottom: 1rem;
	list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
	float: left;
	padding-right: 0.5rem;
	color: #6c757d;
	content: var(--bs-breadcrumb-divider, "/")
		/* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
	color: #6c757d;
}

.pagination {
	display: flex;
	padding-left: 0;
	list-style: none;
}

.page-link {
	position: relative;
	display: block;
	color: #043087;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #dee2e6;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.page-link {
		transition: none;
	}
}
.page-link:hover {
	z-index: 2;
	color: #03266c;
	background-color: #e9ecef;
	border-color: #dee2e6;
}
.page-link:focus {
	z-index: 3;
	color: #03266c;
	background-color: #e9ecef;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}
.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}
.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
}

.page-link {
	padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.badge {
	display: inline-block;
	padding: 0.25em 0.5em;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.375rem;
}
.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.alert {
	position: relative;
	padding: 0.75rem 0.9375rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.625rem;
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 700;
}

.alert-dismissible {
	padding-right: 3rem;
}
.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 0.9375rem 0.9375rem;
}

.alert-pewter-blue {
	color: #4d5f6e;
	background-color: #e6ecf1;
	border-color: #d9e2ea;
}
.alert-pewter-blue .alert-link {
	color: #3e4c58;
}

.alert-tertiary {
	color: #973000;
	background-color: #fedccc;
	border-color: #fecbb3;
}
.alert-tertiary .alert-link {
	color: #792600;
}

.alert-gray {
	color: #434343;
	background-color: #e2e2e2;
	border-color: #d4d4d4;
}
.alert-gray .alert-link {
	color: #363636;
}

.alert-disabled {
	color: #676767;
	background-color: #eeeeee;
	border-color: #e6e6e6;
}
.alert-disabled .alert-link {
	color: #525252;
}

.alert-primary {
	color: #021d51;
	background-color: #cdd6e7;
	border-color: #b4c1db;
}
.alert-primary .alert-link {
	color: #021741;
}

.alert-secondary {
	color: #115c99;
	background-color: #d2ebff;
	border-color: #bbe0ff;
}
.alert-secondary .alert-link {
	color: #0e4a7a;
}

.alert-success {
	color: #006a26;
	background-color: #ccefd9;
	border-color: #b3e8c6;
}
.alert-success .alert-link {
	color: #00551e;
}

.alert-info {
	color: #055160;
	background-color: #cff4fc;
	border-color: #b6effb;
}
.alert-info .alert-link {
	color: #04414d;
}

.alert-warning {
	color: #5a4a18;
	background-color: #f9f1d8;
	border-color: #f6eac4;
}
.alert-warning .alert-link {
	color: #483b13;
}

.alert-danger {
	color: #970000;
	background-color: #fecccc;
	border-color: #feb3b3;
}
.alert-danger .alert-link {
	color: #790000;
}

.alert-light {
	color: #636464;
	background-color: #fefefe;
	border-color: #fdfdfe;
}
.alert-light .alert-link {
	color: #4f5050;
}

.alert-dark {
	color: #171717;
	background-color: #d4d4d4;
	border-color: #bebebe;
}
.alert-dark .alert-link {
	color: #121212;
}

@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}
.progress {
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.75rem;
	background-color: #e9ecef;
	border-radius: 0.25rem;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: #043087;
	transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
	.progress-bar-animated {
		animation: none;
	}
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius: 0.25rem;
}

.list-group-numbered {
	list-style-type: none;
	counter-reset: section;
}
.list-group-numbered > li::before {
	content: counters(section, ".") ". ";
	counter-increment: section;
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color: #f8f9fa;
}
.list-group-item-action:active {
	color: #212529;
	background-color: #e9ecef;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}
.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
}
.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #043087;
	border-color: #043087;
}
.list-group-item + .list-group-item {
	border-top-width: 0;
}
.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width: 1px;
}

.list-group-horizontal {
	flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
	margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width: 1px;
}

@media (min-width: 576px) {
	.list-group-horizontal-sm {
		flex-direction: row;
	}
	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-sm > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}
@media (min-width: 768px) {
	.list-group-horizontal-md {
		flex-direction: row;
	}
	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-md > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}
@media (min-width: 992px) {
	.list-group-horizontal-lg {
		flex-direction: row;
	}
	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-lg > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}
@media (min-width: 1200px) {
	.list-group-horizontal-xl {
		flex-direction: row;
	}
	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}
@media (min-width: 1400px) {
	.list-group-horizontal-xxl {
		flex-direction: row;
	}
	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: 0.25rem;
		border-top-right-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: 0.25rem;
		border-bottom-left-radius: 0;
	}
	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width: 0;
	}
	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width: 1px;
	}
}
.list-group-flush {
	border-radius: 0;
}
.list-group-flush > .list-group-item {
	border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
	border-bottom-width: 0;
}

.list-group-item-pewter-blue {
	color: #4d5f6e;
	background-color: #e6ecf1;
}
.list-group-item-pewter-blue.list-group-item-action:hover,
.list-group-item-pewter-blue.list-group-item-action:focus {
	color: #4d5f6e;
	background-color: #cfd4d9;
}
.list-group-item-pewter-blue.list-group-item-action.active {
	color: #fff;
	background-color: #4d5f6e;
	border-color: #4d5f6e;
}

.list-group-item-tertiary {
	color: #973000;
	background-color: #fedccc;
}
.list-group-item-tertiary.list-group-item-action:hover,
.list-group-item-tertiary.list-group-item-action:focus {
	color: #973000;
	background-color: #e5c6b8;
}
.list-group-item-tertiary.list-group-item-action.active {
	color: #fff;
	background-color: #973000;
	border-color: #973000;
}

.list-group-item-gray {
	color: #434343;
	background-color: #e2e2e2;
}
.list-group-item-gray.list-group-item-action:hover,
.list-group-item-gray.list-group-item-action:focus {
	color: #434343;
	background-color: #cbcbcb;
}
.list-group-item-gray.list-group-item-action.active {
	color: #fff;
	background-color: #434343;
	border-color: #434343;
}

.list-group-item-disabled {
	color: #676767;
	background-color: #eeeeee;
}
.list-group-item-disabled.list-group-item-action:hover,
.list-group-item-disabled.list-group-item-action:focus {
	color: #676767;
	background-color: #d6d6d6;
}
.list-group-item-disabled.list-group-item-action.active {
	color: #fff;
	background-color: #676767;
	border-color: #676767;
}

.list-group-item-primary {
	color: #021d51;
	background-color: #cdd6e7;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
	color: #021d51;
	background-color: #b9c1d0;
}
.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #021d51;
	border-color: #021d51;
}

.list-group-item-secondary {
	color: #115c99;
	background-color: #d2ebff;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
	color: #115c99;
	background-color: #bdd4e6;
}
.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #115c99;
	border-color: #115c99;
}

.list-group-item-success {
	color: #006a26;
	background-color: #ccefd9;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
	color: #006a26;
	background-color: #b8d7c3;
}
.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #006a26;
	border-color: #006a26;
}

.list-group-item-info {
	color: #055160;
	background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
	color: #055160;
	background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #055160;
	border-color: #055160;
}

.list-group-item-warning {
	color: #5a4a18;
	background-color: #f9f1d8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
	color: #5a4a18;
	background-color: #e0d9c2;
}
.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #5a4a18;
	border-color: #5a4a18;
}

.list-group-item-danger {
	color: #970000;
	background-color: #fecccc;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
	color: #970000;
	background-color: #e5b8b8;
}
.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #970000;
	border-color: #970000;
}

.list-group-item-light {
	color: #636464;
	background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
	color: #636464;
	background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #636464;
	border-color: #636464;
}

.list-group-item-dark {
	color: #171717;
	background-color: #d4d4d4;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
	color: #171717;
	background-color: #bfbfbf;
}
.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #171717;
	border-color: #171717;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: 0.25em 0.25em;
	color: #000;
	background: transparent
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
		center/1em auto no-repeat;
	border: 0;
	border-radius: 0.625rem;
	opacity: 0.5;
}
.btn-close:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.75;
}
.btn-close:focus {
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(4, 48, 135, 0.25);
	opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
	pointer-events: none;
	user-select: none;
	opacity: 0.25;
}

.btn-close-white {
	filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
	width: 350px;
	max-width: 100%;
	font-size: 0.875rem;
	pointer-events: auto;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	border-radius: 0.25rem;
}
.toast.showing {
	opacity: 0;
}
.toast:not(.show) {
	display: none;
}

.toast-container {
	width: max-content;
	max-width: 100%;
	pointer-events: none;
}
.toast-container > :not(:last-child) {
	margin-bottom: 0.75rem;
}

.toast-header {
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	color: #6c757d;
	background-color: rgba(255, 255, 255, 0.85);
	background-clip: padding-box;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
	margin-right: -0.375rem;
	margin-left: 0.75rem;
}

.toast-body {
	padding: 0.75rem;
	word-wrap: break-word;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1055;
	display: none;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}
.modal.show .modal-dialog {
	transform: none;
}
.modal.modal-static .modal-dialog {
	transform: scale(1.02);
}

.modal-dialog-scrollable {
	height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
	max-height: 100%;
	overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	min-height: calc(100% - 1rem);
}

.modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.5rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: 2.5rem 2.5rem 0;
	border-bottom: 0 solid #dee2e6;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
	padding: 1.25rem 1.25rem;
	margin: -1.25rem -1.25rem -1.25rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 2.5rem;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	padding: 2.25rem;
	border-top: 0 solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
	margin: 0.25rem;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}

	.modal-dialog-scrollable {
		height: calc(100% - 3.5rem);
	}

	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}

	.modal-sm {
		max-width: 300px;
	}
}
@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px;
	}
}
@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px;
	}
}
.modal-fullscreen {
	width: 100vw;
	max-width: none;
	height: 100%;
	margin: 0;
}
.modal-fullscreen .modal-content {
	height: 100%;
	border: 0;
	border-radius: 0;
}
.modal-fullscreen .modal-header {
	border-radius: 0;
}
.modal-fullscreen .modal-body {
	overflow-y: auto;
}
.modal-fullscreen .modal-footer {
	border-radius: 0;
}

@media (max-width: 575.98px) {
	.modal-fullscreen-sm-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-sm-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-sm-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-sm-down .modal-footer {
		border-radius: 0;
	}
}
@media (max-width: 767.98px) {
	.modal-fullscreen-md-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-md-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-md-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-md-down .modal-footer {
		border-radius: 0;
	}
}
@media (max-width: 991.98px) {
	.modal-fullscreen-lg-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-lg-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-lg-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-lg-down .modal-footer {
		border-radius: 0;
	}
}
@media (max-width: 1199.98px) {
	.modal-fullscreen-xl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-xl-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-xl-down .modal-footer {
		border-radius: 0;
	}
}
@media (max-width: 1399.98px) {
	.modal-fullscreen-xxl-down {
		width: 100vw;
		max-width: none;
		height: 100%;
		margin: 0;
	}
	.modal-fullscreen-xxl-down .modal-content {
		height: 100%;
		border: 0;
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-header {
		border-radius: 0;
	}
	.modal-fullscreen-xxl-down .modal-body {
		overflow-y: auto;
	}
	.modal-fullscreen-xxl-down .modal-footer {
		border-radius: 0;
	}
}
.tooltip {
	position: absolute;
	z-index: 1080;
	display: block;
	margin: 0;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
}
.tooltip.show {
	opacity: 0.9;
}
.tooltip .tooltip-arrow {
	position: absolute;
	display: block;
	width: 0.8rem;
	height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
	position: absolute;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
	bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
	top: -1px;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
	right: -1px;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
	top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
	bottom: -1px;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
	left: -1px;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: #000;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 0.25rem;
}

.popover {
	position: absolute;
	top: 0;
	left: 0 /* rtl:ignore */;
	z-index: 1070;
	display: block;
	max-width: 276px;
	font-family: var(--bs-font-sans-serif);
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
}
.popover .popover-arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
	bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
	left: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
	top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
	right: calc(-0.5rem - 1px);
	width: 0.5rem;
	height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: #fff;
}

.popover-header {
	padding: 0.5rem 1rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #f0f0f0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
	display: none;
}

.popover-body {
	padding: 1rem 1rem;
	color: #212529;
}

.carousel {
	position: relative;
}

.carousel.pointer-event {
	touch-action: pan-y;
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden;
}
.carousel-inner::after {
	display: block;
	clear: both;
	content: "";
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	backface-visibility: hidden;
	transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-item {
		transition: none;
	}
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
	display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
	transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
	transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
	z-index: 1;
	opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-fade .active.carousel-item-start,
	.carousel-fade .active.carousel-item-end {
		transition: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: none;
	border: 0;
	opacity: 0.5;
	transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-control-prev,
	.carousel-control-next {
		transition: none;
	}
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: 0.9;
}

.carousel-control-prev {
	left: 0;
}

.carousel-control-next {
	right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none;
}
.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: 0.5;
	transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
	.carousel-indicators [data-bs-target] {
		transition: none;
	}
}
.carousel-indicators .active {
	opacity: 1;
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
	filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000;
}
.carousel-dark .carousel-caption {
	color: #000;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg) /* rtl:ignore */;
	}
}
.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
	width: 1rem;
	height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
	.spinner-border,
	.spinner-grow {
		animation-duration: 1.5s;
	}
}
.offcanvas {
	position: fixed;
	bottom: 0;
	z-index: 1045;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	visibility: hidden;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
	transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.offcanvas {
		transition: none;
	}
}

.offcanvas-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.offcanvas-backdrop.fade {
	opacity: 0;
}
.offcanvas-backdrop.show {
	opacity: 0.5;
}

.offcanvas-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
	padding: 0.5rem 0.5rem;
	margin-top: -0.5rem;
	margin-right: -0.5rem;
	margin-bottom: -0.5rem;
}

.offcanvas-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.offcanvas-body {
	flex-grow: 1;
	padding: 1rem 1rem;
	overflow-y: auto;
}

.offcanvas-start {
	top: 0;
	left: 0;
	width: 400px;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(-100%);
}

.offcanvas-end {
	top: 0;
	right: 0;
	width: 400px;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(100%);
}

.offcanvas-top {
	top: 0;
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(-100%);
}

.offcanvas-bottom {
	right: 0;
	left: 0;
	height: 30vh;
	max-height: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateY(100%);
}

.offcanvas.show {
	transform: none;
}

.placeholder {
	display: inline-block;
	min-height: 1em;
	vertical-align: middle;
	cursor: wait;
	background-color: currentColor;
	opacity: 0.5;
}
.placeholder.btn::before {
	display: inline-block;
	content: "";
}

.placeholder-xs {
	min-height: 0.6em;
}

.placeholder-sm {
	min-height: 0.8em;
}

.placeholder-lg {
	min-height: 1.2em;
}

.placeholder-glow .placeholder {
	animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
	50% {
		opacity: 0.2;
	}
}
.placeholder-wave {
	mask-image: linear-gradient(
		130deg,
		#000 55%,
		rgba(0, 0, 0, 0.8) 75%,
		#000 95%
	);
	mask-size: 200% 100%;
	animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
	100% {
		mask-position: -200% 0%;
	}
}
.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.link-pewter-blue {
	color: #809fb8;
}
.link-pewter-blue:hover,
.link-pewter-blue:focus {
	color: #99b2c6;
}

.link-tertiary {
	color: #fc5000;
}
.link-tertiary:hover,
.link-tertiary:focus {
	color: #fd7333;
}

.link-gray {
	color: #707070;
}
.link-gray:hover,
.link-gray:focus {
	color: #5a5a5a;
}

.link-disabled {
	color: #ababac;
}
.link-disabled:hover,
.link-disabled:focus {
	color: #bcbcbd;
}

.link-primary {
	color: #043087;
}
.link-primary:hover,
.link-primary:focus {
	color: #03266c;
}

.link-secondary {
	color: #1c99ff;
}
.link-secondary:hover,
.link-secondary:focus {
	color: #49adff;
}

.link-success {
	color: #00b140;
}
.link-success:hover,
.link-success:focus {
	color: #33c166;
}

.link-info {
	color: #0dcaf0;
}
.link-info:hover,
.link-info:focus {
	color: #3dd5f3;
}

.link-warning {
	color: #e2b93b;
}
.link-warning:hover,
.link-warning:focus {
	color: #e8c762;
}

.link-danger {
	color: #fc0000;
}
.link-danger:hover,
.link-danger:focus {
	color: #fd3333;
}

.link-light {
	color: #f8f9fa;
}
.link-light:hover,
.link-light:focus {
	color: #f9fafb;
}

.link-dark {
	color: #262626;
}
.link-dark:hover,
.link-dark:focus {
	color: #1e1e1e;
}

.ratio {
	position: relative;
	width: 100%;
}
.ratio::before {
	display: block;
	padding-top: var(--bs-aspect-ratio);
	content: "";
}
.ratio > * {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ratio-1x1 {
	--bs-aspect-ratio: 100%;
}

.ratio-4x3 {
	--bs-aspect-ratio: 75%;
}

.ratio-16x9 {
	--bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
	--bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sticky-top {
	position: sticky;
	top: 0;
	z-index: 1020;
}

@media (min-width: 576px) {
	.sticky-sm-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
@media (min-width: 768px) {
	.sticky-md-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
@media (min-width: 992px) {
	.sticky-lg-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
@media (min-width: 1200px) {
	.sticky-xl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
@media (min-width: 1400px) {
	.sticky-xxl-top {
		position: sticky;
		top: 0;
		z-index: 1020;
	}
}
.hstack {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
}

.vstack {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	content: "";
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.vr {
	display: inline-block;
	align-self: stretch;
	width: 1px;
	min-height: 1em;
	background-color: currentColor;
	opacity: 0.25;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.float-start {
	float: left !important;
}

.float-end {
	float: right !important;
}

.float-none {
	float: none !important;
}

.opacity-0 {
	opacity: 0 !important;
}

.opacity-25 {
	opacity: 0.25 !important;
}

.opacity-50 {
	opacity: 0.5 !important;
}

.opacity-75 {
	opacity: 0.75 !important;
}

.opacity-100 {
	opacity: 1 !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}

.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}

.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}

.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}

.translate-middle {
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}

.border {
	border: 1px solid #dee2e6 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top {
	border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-end {
	border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
	border-right: 0 !important;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-start {
	border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
	border-left: 0 !important;
}

.border-pewter-blue {
	border-color: #809fb8 !important;
}

.border-tertiary {
	border-color: #fc5000 !important;
}

.border-gray {
	border-color: #707070 !important;
}

.border-disabled {
	border-color: #ababac !important;
}

.border-primary {
	border-color: #043087 !important;
}

.border-secondary {
	border-color: #1c99ff !important;
}

.border-success {
	border-color: #00b140 !important;
}

.border-info {
	border-color: #0dcaf0 !important;
}

.border-warning {
	border-color: #e2b93b !important;
}

.border-danger {
	border-color: #fc0000 !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #262626 !important;
}

.border-white {
	border-color: #fff !important;
}

.border-1 {
	border-width: 1px !important;
}

.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.vw-100 {
	width: 100vw !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mh-100 {
	max-height: 100% !important;
}

.vh-100 {
	height: 100vh !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.gap-0 {
	gap: 0 !important;
}

.gap-1 {
	gap: 0.25rem !important;
}

.gap-2 {
	gap: 0.5rem !important;
}

.gap-3 {
	gap: 1rem !important;
}

.gap-4 {
	gap: 1.5rem !important;
}

.gap-5 {
	gap: 3rem !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0 {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0 {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

.font-monospace {
	font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.29375rem + 0.525vw) !important;
}

.fs-4 {
	font-size: 1.25rem !important;
}

.fs-5 {
	font-size: 1rem !important;
}

.fs-6 {
	font-size: 0.875rem !important;
}

.fs-7 {
	font-size: 0.6875rem !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 600 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-end span.d-flex {
	justify-content: flex-end;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

/* rtl:end:remove */
.text-pewter-blue {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-pewter-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-disabled {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-disabled-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
	--bs-text-opacity: 1;
	color: #6c757d !important;
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important;
}

.text-opacity-25 {
	--bs-text-opacity: 0.25;
}

.text-opacity-50 {
	--bs-text-opacity: 0.5;
}

.text-opacity-75 {
	--bs-text-opacity: 0.75;
}

.text-opacity-100 {
	--bs-text-opacity: 1;
}

.bg-pewter-blue {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-pewter-blue-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-tertiary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-tertiary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-gray {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-disabled {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-disabled-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-primary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-primary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-secondary {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-secondary-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-success {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-success-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-info {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-warning-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-danger {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
	--bs-bg-opacity: 1;
	background-color: rgba(
		var(--bs-body-bg-rgb),
		var(--bs-bg-opacity)
	) !important;
}

.bg-transparent {
	--bs-bg-opacity: 1;
	background-color: transparent !important;
}

.bg-opacity-10 {
	--bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
	--bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
	--bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
	--bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
	--bs-bg-opacity: 1;
}

.bg-gradient {
	background-image: var(--bs-gradient) !important;
}

.user-select-all {
	user-select: all !important;
}

.user-select-auto {
	user-select: auto !important;
}

.user-select-none {
	user-select: none !important;
}

.pe-none {
	pointer-events: none !important;
}

.pe-auto {
	pointer-events: auto !important;
}

.rounded {
	border-radius: 0.625rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.rounded-1 {
	border-radius: 0.2rem !important;
}

.rounded-2 {
	border-radius: 0.625rem !important;
}

.rounded-3 {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-top {
	border-top-left-radius: 0.625rem !important;
	border-top-right-radius: 0.625rem !important;
}

.rounded-end {
	border-top-right-radius: 0.625rem !important;
	border-bottom-right-radius: 0.625rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.625rem !important;
	border-bottom-left-radius: 0.625rem !important;
}

.rounded-start {
	border-bottom-left-radius: 0.625rem !important;
	border-top-left-radius: 0.625rem !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

@media (min-width: 576px) {
	.float-sm-start {
		float: left !important;
	}

	.float-sm-end {
		float: right !important;
	}

	.float-sm-none {
		float: none !important;
	}

	.d-sm-inline {
		display: inline !important;
	}

	.d-sm-inline-block {
		display: inline-block !important;
	}

	.d-sm-block {
		display: block !important;
	}

	.d-sm-grid {
		display: grid !important;
	}

	.d-sm-table {
		display: table !important;
	}

	.d-sm-table-row {
		display: table-row !important;
	}

	.d-sm-table-cell {
		display: table-cell !important;
	}

	.d-sm-flex {
		display: flex !important;
	}

	.d-sm-inline-flex {
		display: inline-flex !important;
	}

	.d-sm-none {
		display: none !important;
	}

	.flex-sm-fill {
		flex: 1 1 auto !important;
	}

	.flex-sm-row {
		flex-direction: row !important;
	}

	.flex-sm-column {
		flex-direction: column !important;
	}

	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}

	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-sm-0 {
		gap: 0 !important;
	}

	.gap-sm-1 {
		gap: 0.25rem !important;
	}

	.gap-sm-2 {
		gap: 0.5rem !important;
	}

	.gap-sm-3 {
		gap: 1rem !important;
	}

	.gap-sm-4 {
		gap: 1.5rem !important;
	}

	.gap-sm-5 {
		gap: 3rem !important;
	}

	.justify-content-sm-start {
		justify-content: flex-start !important;
	}

	.justify-content-sm-end {
		justify-content: flex-end !important;
	}

	.justify-content-sm-center {
		justify-content: center !important;
	}

	.justify-content-sm-between {
		justify-content: space-between !important;
	}

	.justify-content-sm-around {
		justify-content: space-around !important;
	}

	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-sm-start {
		align-items: flex-start !important;
	}

	.align-items-sm-end {
		align-items: flex-end !important;
	}

	.align-items-sm-center {
		align-items: center !important;
	}

	.align-items-sm-baseline {
		align-items: baseline !important;
	}

	.align-items-sm-stretch {
		align-items: stretch !important;
	}

	.align-content-sm-start {
		align-content: flex-start !important;
	}

	.align-content-sm-end {
		align-content: flex-end !important;
	}

	.align-content-sm-center {
		align-content: center !important;
	}

	.align-content-sm-between {
		align-content: space-between !important;
	}

	.align-content-sm-around {
		align-content: space-around !important;
	}

	.align-content-sm-stretch {
		align-content: stretch !important;
	}

	.align-self-sm-auto {
		align-self: auto !important;
	}

	.align-self-sm-start {
		align-self: flex-start !important;
	}

	.align-self-sm-end {
		align-self: flex-end !important;
	}

	.align-self-sm-center {
		align-self: center !important;
	}

	.align-self-sm-baseline {
		align-self: baseline !important;
	}

	.align-self-sm-stretch {
		align-self: stretch !important;
	}

	.order-sm-first {
		order: -1 !important;
	}

	.order-sm-0 {
		order: 0 !important;
	}

	.order-sm-1 {
		order: 1 !important;
	}

	.order-sm-2 {
		order: 2 !important;
	}

	.order-sm-3 {
		order: 3 !important;
	}

	.order-sm-4 {
		order: 4 !important;
	}

	.order-sm-5 {
		order: 5 !important;
	}

	.order-sm-last {
		order: 6 !important;
	}

	.m-sm-0 {
		margin: 0 !important;
	}

	.m-sm-1 {
		margin: 0.25rem !important;
	}

	.m-sm-2 {
		margin: 0.5rem !important;
	}

	.m-sm-3 {
		margin: 1rem !important;
	}

	.m-sm-4 {
		margin: 1.5rem !important;
	}

	.m-sm-5 {
		margin: 3rem !important;
	}

	.m-sm-auto {
		margin: auto !important;
	}

	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-sm-0 {
		margin-top: 0 !important;
	}

	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}

	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}

	.mt-sm-3 {
		margin-top: 1rem !important;
	}

	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}

	.mt-sm-5 {
		margin-top: 3rem !important;
	}

	.mt-sm-auto {
		margin-top: auto !important;
	}

	.me-sm-0 {
		margin-right: 0 !important;
	}

	.me-sm-1 {
		margin-right: 0.25rem !important;
	}

	.me-sm-2 {
		margin-right: 0.5rem !important;
	}

	.me-sm-3 {
		margin-right: 1rem !important;
	}

	.me-sm-4 {
		margin-right: 1.5rem !important;
	}

	.me-sm-5 {
		margin-right: 3rem !important;
	}

	.me-sm-auto {
		margin-right: auto !important;
	}

	.mb-sm-0 {
		margin-bottom: 0 !important;
	}

	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}

	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}

	.mb-sm-auto {
		margin-bottom: auto !important;
	}

	.ms-sm-0 {
		margin-left: 0 !important;
	}

	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}

	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}

	.ms-sm-3 {
		margin-left: 1rem !important;
	}

	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}

	.ms-sm-5 {
		margin-left: 3rem !important;
	}

	.ms-sm-auto {
		margin-left: auto !important;
	}

	.p-sm-0 {
		padding: 0 !important;
	}

	.p-sm-1 {
		padding: 0.25rem !important;
	}

	.p-sm-2 {
		padding: 0.5rem !important;
	}

	.p-sm-3 {
		padding: 1rem !important;
	}

	.p-sm-4 {
		padding: 1.5rem !important;
	}

	.p-sm-5 {
		padding: 3rem !important;
	}

	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-sm-0 {
		padding-top: 0 !important;
	}

	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}

	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}

	.pt-sm-3 {
		padding-top: 1rem !important;
	}

	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}

	.pt-sm-5 {
		padding-top: 3rem !important;
	}

	.pe-sm-0 {
		padding-right: 0 !important;
	}

	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}

	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}

	.pe-sm-3 {
		padding-right: 1rem !important;
	}

	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}

	.pe-sm-5 {
		padding-right: 3rem !important;
	}

	.pb-sm-0 {
		padding-bottom: 0 !important;
	}

	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}

	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}

	.ps-sm-0 {
		padding-left: 0 !important;
	}

	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}

	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}

	.ps-sm-3 {
		padding-left: 1rem !important;
	}

	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}

	.ps-sm-5 {
		padding-left: 3rem !important;
	}

	.text-sm-start {
		text-align: left !important;
	}

	.text-sm-end {
		text-align: right !important;
	}

	.text-sm-center {
		text-align: center !important;
	}
}
@media (min-width: 768px) {
	.float-md-start {
		float: left !important;
	}

	.float-md-end {
		float: right !important;
	}

	.float-md-none {
		float: none !important;
	}

	.d-md-inline {
		display: inline !important;
	}

	.d-md-inline-block {
		display: inline-block !important;
	}

	.d-md-block {
		display: block !important;
	}

	.d-md-grid {
		display: grid !important;
	}

	.d-md-table {
		display: table !important;
	}

	.d-md-table-row {
		display: table-row !important;
	}

	.d-md-table-cell {
		display: table-cell !important;
	}

	.d-md-flex {
		display: flex !important;
	}

	.d-md-inline-flex {
		display: inline-flex !important;
	}

	.d-md-none {
		display: none !important;
	}

	.flex-md-fill {
		flex: 1 1 auto !important;
	}

	.flex-md-row {
		flex-direction: row !important;
	}

	.flex-md-column {
		flex-direction: column !important;
	}

	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-md-wrap {
		flex-wrap: wrap !important;
	}

	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-md-0 {
		gap: 0 !important;
	}

	.gap-md-1 {
		gap: 0.25rem !important;
	}

	.gap-md-2 {
		gap: 0.5rem !important;
	}

	.gap-md-3 {
		gap: 1rem !important;
	}

	.gap-md-4 {
		gap: 1.5rem !important;
	}

	.gap-md-5 {
		gap: 3rem !important;
	}

	.justify-content-md-start {
		justify-content: flex-start !important;
	}

	.justify-content-md-end {
		justify-content: flex-end !important;
	}

	.justify-content-md-center {
		justify-content: center !important;
	}

	.justify-content-md-between {
		justify-content: space-between !important;
	}

	.justify-content-md-around {
		justify-content: space-around !important;
	}

	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-md-start {
		align-items: flex-start !important;
	}

	.align-items-md-end {
		align-items: flex-end !important;
	}

	.align-items-md-center {
		align-items: center !important;
	}

	.align-items-md-baseline {
		align-items: baseline !important;
	}

	.align-items-md-stretch {
		align-items: stretch !important;
	}

	.align-content-md-start {
		align-content: flex-start !important;
	}

	.align-content-md-end {
		align-content: flex-end !important;
	}

	.align-content-md-center {
		align-content: center !important;
	}

	.align-content-md-between {
		align-content: space-between !important;
	}

	.align-content-md-around {
		align-content: space-around !important;
	}

	.align-content-md-stretch {
		align-content: stretch !important;
	}

	.align-self-md-auto {
		align-self: auto !important;
	}

	.align-self-md-start {
		align-self: flex-start !important;
	}

	.align-self-md-end {
		align-self: flex-end !important;
	}

	.align-self-md-center {
		align-self: center !important;
	}

	.align-self-md-baseline {
		align-self: baseline !important;
	}

	.align-self-md-stretch {
		align-self: stretch !important;
	}

	.order-md-first {
		order: -1 !important;
	}

	.order-md-0 {
		order: 0 !important;
	}

	.order-md-1 {
		order: 1 !important;
	}

	.order-md-2 {
		order: 2 !important;
	}

	.order-md-3 {
		order: 3 !important;
	}

	.order-md-4 {
		order: 4 !important;
	}

	.order-md-5 {
		order: 5 !important;
	}

	.order-md-last {
		order: 6 !important;
	}

	.m-md-0 {
		margin: 0 !important;
	}

	.m-md-1 {
		margin: 0.25rem !important;
	}

	.m-md-2 {
		margin: 0.5rem !important;
	}

	.m-md-3 {
		margin: 1rem !important;
	}

	.m-md-4 {
		margin: 1.5rem !important;
	}

	.m-md-5 {
		margin: 3rem !important;
	}

	.m-md-auto {
		margin: auto !important;
	}

	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-md-0 {
		margin-top: 0 !important;
	}

	.mt-md-1 {
		margin-top: 0.25rem !important;
	}

	.mt-md-2 {
		margin-top: 0.5rem !important;
	}

	.mt-md-3 {
		margin-top: 1rem !important;
	}

	.mt-md-4 {
		margin-top: 1.5rem !important;
	}

	.mt-md-5 {
		margin-top: 3rem !important;
	}

	.mt-md-auto {
		margin-top: auto !important;
	}

	.me-md-0 {
		margin-right: 0 !important;
	}

	.me-md-1 {
		margin-right: 0.25rem !important;
	}

	.me-md-2 {
		margin-right: 0.5rem !important;
	}

	.me-md-3 {
		margin-right: 1rem !important;
	}

	.me-md-4 {
		margin-right: 1.5rem !important;
	}

	.me-md-5 {
		margin-right: 3rem !important;
	}

	.me-md-auto {
		margin-right: auto !important;
	}

	.mb-md-0 {
		margin-bottom: 0 !important;
	}

	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-md-3 {
		margin-bottom: 1rem !important;
	}

	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-md-5 {
		margin-bottom: 3rem !important;
	}

	.mb-md-auto {
		margin-bottom: auto !important;
	}

	.ms-md-0 {
		margin-left: 0 !important;
	}

	.ms-md-1 {
		margin-left: 0.25rem !important;
	}

	.ms-md-2 {
		margin-left: 0.5rem !important;
	}

	.ms-md-3 {
		margin-left: 1rem !important;
	}

	.ms-md-4 {
		margin-left: 1.5rem !important;
	}

	.ms-md-5 {
		margin-left: 3rem !important;
	}

	.ms-md-auto {
		margin-left: auto !important;
	}

	.p-md-0 {
		padding: 0 !important;
	}

	.p-md-1 {
		padding: 0.25rem !important;
	}

	.p-md-2 {
		padding: 0.5rem !important;
	}

	.p-md-3 {
		padding: 1rem !important;
	}

	.p-md-4 {
		padding: 1.5rem !important;
	}

	.p-md-5 {
		padding: 3rem !important;
	}

	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-md-0 {
		padding-top: 0 !important;
	}

	.pt-md-1 {
		padding-top: 0.25rem !important;
	}

	.pt-md-2 {
		padding-top: 0.5rem !important;
	}

	.pt-md-3 {
		padding-top: 1rem !important;
	}

	.pt-md-4 {
		padding-top: 1.5rem !important;
	}

	.pt-md-5 {
		padding-top: 3rem !important;
	}

	.pe-md-0 {
		padding-right: 0 !important;
	}

	.pe-md-1 {
		padding-right: 0.25rem !important;
	}

	.pe-md-2 {
		padding-right: 0.5rem !important;
	}

	.pe-md-3 {
		padding-right: 1rem !important;
	}

	.pe-md-4 {
		padding-right: 1.5rem !important;
	}

	.pe-md-5 {
		padding-right: 3rem !important;
	}

	.pb-md-0 {
		padding-bottom: 0 !important;
	}

	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-md-3 {
		padding-bottom: 1rem !important;
	}

	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-md-5 {
		padding-bottom: 3rem !important;
	}

	.ps-md-0 {
		padding-left: 0 !important;
	}

	.ps-md-1 {
		padding-left: 0.25rem !important;
	}

	.ps-md-2 {
		padding-left: 0.5rem !important;
	}

	.ps-md-3 {
		padding-left: 1rem !important;
	}

	.ps-md-4 {
		padding-left: 1.5rem !important;
	}

	.ps-md-5 {
		padding-left: 3rem !important;
	}

	.text-md-start {
		text-align: left !important;
	}

	.text-md-end {
		text-align: right !important;
	}

	.text-md-center {
		text-align: center !important;
	}
}
@media (min-width: 992px) {
	.float-lg-start {
		float: left !important;
	}

	.float-lg-end {
		float: right !important;
	}

	.float-lg-none {
		float: none !important;
	}

	.d-lg-inline {
		display: inline !important;
	}

	.d-lg-inline-block {
		display: inline-block !important;
	}

	.d-lg-block {
		display: block !important;
	}

	.d-lg-grid {
		display: grid !important;
	}

	.d-lg-table {
		display: table !important;
	}

	.d-lg-table-row {
		display: table-row !important;
	}

	.d-lg-table-cell {
		display: table-cell !important;
	}

	.d-lg-flex {
		display: flex !important;
	}

	.d-lg-inline-flex {
		display: inline-flex !important;
	}

	.d-lg-none {
		display: none !important;
	}

	.flex-lg-fill {
		flex: 1 1 auto !important;
	}

	.flex-lg-row {
		flex-direction: row !important;
	}

	.flex-lg-column {
		flex-direction: column !important;
	}

	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}

	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-lg-0 {
		gap: 0 !important;
	}

	.gap-lg-1 {
		gap: 0.25rem !important;
	}

	.gap-lg-2 {
		gap: 0.5rem !important;
	}

	.gap-lg-3 {
		gap: 1rem !important;
	}

	.gap-lg-4 {
		gap: 1.5rem !important;
	}

	.gap-lg-5 {
		gap: 3rem !important;
	}

	.justify-content-lg-start {
		justify-content: flex-start !important;
	}

	.justify-content-lg-end {
		justify-content: flex-end !important;
	}

	.justify-content-lg-center {
		justify-content: center !important;
	}

	.justify-content-lg-between {
		justify-content: space-between !important;
	}

	.justify-content-lg-around {
		justify-content: space-around !important;
	}

	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-lg-start {
		align-items: flex-start !important;
	}

	.align-items-lg-end {
		align-items: flex-end !important;
	}

	.align-items-lg-center {
		align-items: center !important;
	}

	.align-items-lg-baseline {
		align-items: baseline !important;
	}

	.align-items-lg-stretch {
		align-items: stretch !important;
	}

	.align-content-lg-start {
		align-content: flex-start !important;
	}

	.align-content-lg-end {
		align-content: flex-end !important;
	}

	.align-content-lg-center {
		align-content: center !important;
	}

	.align-content-lg-between {
		align-content: space-between !important;
	}

	.align-content-lg-around {
		align-content: space-around !important;
	}

	.align-content-lg-stretch {
		align-content: stretch !important;
	}

	.align-self-lg-auto {
		align-self: auto !important;
	}

	.align-self-lg-start {
		align-self: flex-start !important;
	}

	.align-self-lg-end {
		align-self: flex-end !important;
	}

	.align-self-lg-center {
		align-self: center !important;
	}

	.align-self-lg-baseline {
		align-self: baseline !important;
	}

	.align-self-lg-stretch {
		align-self: stretch !important;
	}

	.order-lg-first {
		order: -1 !important;
	}

	.order-lg-0 {
		order: 0 !important;
	}

	.order-lg-1 {
		order: 1 !important;
	}

	.order-lg-2 {
		order: 2 !important;
	}

	.order-lg-3 {
		order: 3 !important;
	}

	.order-lg-4 {
		order: 4 !important;
	}

	.order-lg-5 {
		order: 5 !important;
	}

	.order-lg-last {
		order: 6 !important;
	}

	.m-lg-0 {
		margin: 0 !important;
	}

	.m-lg-1 {
		margin: 0.25rem !important;
	}

	.m-lg-2 {
		margin: 0.5rem !important;
	}

	.m-lg-3 {
		margin: 1rem !important;
	}

	.m-lg-4 {
		margin: 1.5rem !important;
	}

	.m-lg-5 {
		margin: 3rem !important;
	}

	.m-lg-auto {
		margin: auto !important;
	}

	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-lg-0 {
		margin-top: 0 !important;
	}

	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}

	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}

	.mt-lg-3 {
		margin-top: 1rem !important;
	}

	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}

	.mt-lg-5 {
		margin-top: 3rem !important;
	}

	.mt-lg-auto {
		margin-top: auto !important;
	}

	.me-lg-0 {
		margin-right: 0 !important;
	}

	.me-lg-1 {
		margin-right: 0.25rem !important;
	}

	.me-lg-2 {
		margin-right: 0.5rem !important;
	}

	.me-lg-3 {
		margin-right: 1rem !important;
	}

	.me-lg-4 {
		margin-right: 1.5rem !important;
	}

	.me-lg-5 {
		margin-right: 3rem !important;
	}

	.me-lg-auto {
		margin-right: auto !important;
	}

	.mb-lg-0 {
		margin-bottom: 0 !important;
	}

	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}

	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}

	.mb-lg-auto {
		margin-bottom: auto !important;
	}

	.ms-lg-0 {
		margin-left: 0 !important;
	}

	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}

	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}

	.ms-lg-3 {
		margin-left: 1rem !important;
	}

	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}

	.ms-lg-5 {
		margin-left: 3rem !important;
	}

	.ms-lg-auto {
		margin-left: auto !important;
	}

	.p-lg-0 {
		padding: 0 !important;
	}

	.p-lg-1 {
		padding: 0.25rem !important;
	}

	.p-lg-2 {
		padding: 0.5rem !important;
	}

	.p-lg-3 {
		padding: 1rem !important;
	}

	.p-lg-4 {
		padding: 1.5rem !important;
	}

	.p-lg-5 {
		padding: 3rem !important;
	}

	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-lg-0 {
		padding-top: 0 !important;
	}

	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}

	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}

	.pt-lg-3 {
		padding-top: 1rem !important;
	}

	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}

	.pt-lg-5 {
		padding-top: 3rem !important;
	}

	.pe-lg-0 {
		padding-right: 0 !important;
	}

	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}

	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}

	.pe-lg-3 {
		padding-right: 1rem !important;
	}

	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}

	.pe-lg-5 {
		padding-right: 3rem !important;
	}

	.pb-lg-0 {
		padding-bottom: 0 !important;
	}

	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}

	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}

	.ps-lg-0 {
		padding-left: 0 !important;
	}

	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}

	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}

	.ps-lg-3 {
		padding-left: 1rem !important;
	}

	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}

	.ps-lg-5 {
		padding-left: 3rem !important;
	}

	.text-lg-start {
		text-align: left !important;
	}

	.text-lg-end {
		text-align: right !important;
	}

	.text-lg-center {
		text-align: center !important;
	}
}
@media (min-width: 1200px) {
	.float-xl-start {
		float: left !important;
	}

	.float-xl-end {
		float: right !important;
	}

	.float-xl-none {
		float: none !important;
	}

	.d-xl-inline {
		display: inline !important;
	}

	.d-xl-inline-block {
		display: inline-block !important;
	}

	.d-xl-block {
		display: block !important;
	}

	.d-xl-grid {
		display: grid !important;
	}

	.d-xl-table {
		display: table !important;
	}

	.d-xl-table-row {
		display: table-row !important;
	}

	.d-xl-table-cell {
		display: table-cell !important;
	}

	.d-xl-flex {
		display: flex !important;
	}

	.d-xl-inline-flex {
		display: inline-flex !important;
	}

	.d-xl-none {
		display: none !important;
	}

	.flex-xl-fill {
		flex: 1 1 auto !important;
	}

	.flex-xl-row {
		flex-direction: row !important;
	}

	.flex-xl-column {
		flex-direction: column !important;
	}

	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}

	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-xl-0 {
		gap: 0 !important;
	}

	.gap-xl-1 {
		gap: 0.25rem !important;
	}

	.gap-xl-2 {
		gap: 0.5rem !important;
	}

	.gap-xl-3 {
		gap: 1rem !important;
	}

	.gap-xl-4 {
		gap: 1.5rem !important;
	}

	.gap-xl-5 {
		gap: 3rem !important;
	}

	.justify-content-xl-start {
		justify-content: flex-start !important;
	}

	.justify-content-xl-end {
		justify-content: flex-end !important;
	}

	.justify-content-xl-center {
		justify-content: center !important;
	}

	.justify-content-xl-between {
		justify-content: space-between !important;
	}

	.justify-content-xl-around {
		justify-content: space-around !important;
	}

	.justify-content-xl-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-xl-start {
		align-items: flex-start !important;
	}

	.align-items-xl-end {
		align-items: flex-end !important;
	}

	.align-items-xl-center {
		align-items: center !important;
	}

	.align-items-xl-baseline {
		align-items: baseline !important;
	}

	.align-items-xl-stretch {
		align-items: stretch !important;
	}

	.align-content-xl-start {
		align-content: flex-start !important;
	}

	.align-content-xl-end {
		align-content: flex-end !important;
	}

	.align-content-xl-center {
		align-content: center !important;
	}

	.align-content-xl-between {
		align-content: space-between !important;
	}

	.align-content-xl-around {
		align-content: space-around !important;
	}

	.align-content-xl-stretch {
		align-content: stretch !important;
	}

	.align-self-xl-auto {
		align-self: auto !important;
	}

	.align-self-xl-start {
		align-self: flex-start !important;
	}

	.align-self-xl-end {
		align-self: flex-end !important;
	}

	.align-self-xl-center {
		align-self: center !important;
	}

	.align-self-xl-baseline {
		align-self: baseline !important;
	}

	.align-self-xl-stretch {
		align-self: stretch !important;
	}

	.order-xl-first {
		order: -1 !important;
	}

	.order-xl-0 {
		order: 0 !important;
	}

	.order-xl-1 {
		order: 1 !important;
	}

	.order-xl-2 {
		order: 2 !important;
	}

	.order-xl-3 {
		order: 3 !important;
	}

	.order-xl-4 {
		order: 4 !important;
	}

	.order-xl-5 {
		order: 5 !important;
	}

	.order-xl-last {
		order: 6 !important;
	}

	.m-xl-0 {
		margin: 0 !important;
	}

	.m-xl-1 {
		margin: 0.25rem !important;
	}

	.m-xl-2 {
		margin: 0.5rem !important;
	}

	.m-xl-3 {
		margin: 1rem !important;
	}

	.m-xl-4 {
		margin: 1.5rem !important;
	}

	.m-xl-5 {
		margin: 3rem !important;
	}

	.m-xl-auto {
		margin: auto !important;
	}

	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-xl-0 {
		margin-top: 0 !important;
	}

	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}

	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}

	.mt-xl-3 {
		margin-top: 1rem !important;
	}

	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}

	.mt-xl-5 {
		margin-top: 3rem !important;
	}

	.mt-xl-auto {
		margin-top: auto !important;
	}

	.me-xl-0 {
		margin-right: 0 !important;
	}

	.me-xl-1 {
		margin-right: 0.25rem !important;
	}

	.me-xl-2 {
		margin-right: 0.5rem !important;
	}

	.me-xl-3 {
		margin-right: 1rem !important;
	}

	.me-xl-4 {
		margin-right: 1.5rem !important;
	}

	.me-xl-5 {
		margin-right: 3rem !important;
	}

	.me-xl-auto {
		margin-right: auto !important;
	}

	.mb-xl-0 {
		margin-bottom: 0 !important;
	}

	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}

	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}

	.mb-xl-auto {
		margin-bottom: auto !important;
	}

	.ms-xl-0 {
		margin-left: 0 !important;
	}

	.ms-xl-1 {
		margin-left: 0.25rem !important;
	}

	.ms-xl-2 {
		margin-left: 0.5rem !important;
	}

	.ms-xl-3 {
		margin-left: 1rem !important;
	}

	.ms-xl-4 {
		margin-left: 1.5rem !important;
	}

	.ms-xl-5 {
		margin-left: 3rem !important;
	}

	.ms-xl-auto {
		margin-left: auto !important;
	}

	.p-xl-0 {
		padding: 0 !important;
	}

	.p-xl-1 {
		padding: 0.25rem !important;
	}

	.p-xl-2 {
		padding: 0.5rem !important;
	}

	.p-xl-3 {
		padding: 1rem !important;
	}

	.p-xl-4 {
		padding: 1.5rem !important;
	}

	.p-xl-5 {
		padding: 3rem !important;
	}

	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-xl-0 {
		padding-top: 0 !important;
	}

	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}

	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}

	.pt-xl-3 {
		padding-top: 1rem !important;
	}

	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}

	.pt-xl-5 {
		padding-top: 3rem !important;
	}

	.pe-xl-0 {
		padding-right: 0 !important;
	}

	.pe-xl-1 {
		padding-right: 0.25rem !important;
	}

	.pe-xl-2 {
		padding-right: 0.5rem !important;
	}

	.pe-xl-3 {
		padding-right: 1rem !important;
	}

	.pe-xl-4 {
		padding-right: 1.5rem !important;
	}

	.pe-xl-5 {
		padding-right: 3rem !important;
	}

	.pb-xl-0 {
		padding-bottom: 0 !important;
	}

	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}

	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}

	.ps-xl-0 {
		padding-left: 0 !important;
	}

	.ps-xl-1 {
		padding-left: 0.25rem !important;
	}

	.ps-xl-2 {
		padding-left: 0.5rem !important;
	}

	.ps-xl-3 {
		padding-left: 1rem !important;
	}

	.ps-xl-4 {
		padding-left: 1.5rem !important;
	}

	.ps-xl-5 {
		padding-left: 3rem !important;
	}

	.text-xl-start {
		text-align: left !important;
	}

	.text-xl-end {
		text-align: right !important;
	}

	.text-xl-center {
		text-align: center !important;
	}
}
@media (min-width: 1400px) {
	.float-xxl-start {
		float: left !important;
	}

	.float-xxl-end {
		float: right !important;
	}

	.float-xxl-none {
		float: none !important;
	}

	.d-xxl-inline {
		display: inline !important;
	}

	.d-xxl-inline-block {
		display: inline-block !important;
	}

	.d-xxl-block {
		display: block !important;
	}

	.d-xxl-grid {
		display: grid !important;
	}

	.d-xxl-table {
		display: table !important;
	}

	.d-xxl-table-row {
		display: table-row !important;
	}

	.d-xxl-table-cell {
		display: table-cell !important;
	}

	.d-xxl-flex {
		display: flex !important;
	}

	.d-xxl-inline-flex {
		display: inline-flex !important;
	}

	.d-xxl-none {
		display: none !important;
	}

	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}

	.flex-xxl-row {
		flex-direction: row !important;
	}

	.flex-xxl-column {
		flex-direction: column !important;
	}

	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}

	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}

	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}

	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}

	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}

	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}

	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}

	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}

	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.gap-xxl-0 {
		gap: 0 !important;
	}

	.gap-xxl-1 {
		gap: 0.25rem !important;
	}

	.gap-xxl-2 {
		gap: 0.5rem !important;
	}

	.gap-xxl-3 {
		gap: 1rem !important;
	}

	.gap-xxl-4 {
		gap: 1.5rem !important;
	}

	.gap-xxl-5 {
		gap: 3rem !important;
	}

	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}

	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}

	.justify-content-xxl-center {
		justify-content: center !important;
	}

	.justify-content-xxl-between {
		justify-content: space-between !important;
	}

	.justify-content-xxl-around {
		justify-content: space-around !important;
	}

	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}

	.align-items-xxl-start {
		align-items: flex-start !important;
	}

	.align-items-xxl-end {
		align-items: flex-end !important;
	}

	.align-items-xxl-center {
		align-items: center !important;
	}

	.align-items-xxl-baseline {
		align-items: baseline !important;
	}

	.align-items-xxl-stretch {
		align-items: stretch !important;
	}

	.align-content-xxl-start {
		align-content: flex-start !important;
	}

	.align-content-xxl-end {
		align-content: flex-end !important;
	}

	.align-content-xxl-center {
		align-content: center !important;
	}

	.align-content-xxl-between {
		align-content: space-between !important;
	}

	.align-content-xxl-around {
		align-content: space-around !important;
	}

	.align-content-xxl-stretch {
		align-content: stretch !important;
	}

	.align-self-xxl-auto {
		align-self: auto !important;
	}

	.align-self-xxl-start {
		align-self: flex-start !important;
	}

	.align-self-xxl-end {
		align-self: flex-end !important;
	}

	.align-self-xxl-center {
		align-self: center !important;
	}

	.align-self-xxl-baseline {
		align-self: baseline !important;
	}

	.align-self-xxl-stretch {
		align-self: stretch !important;
	}

	.order-xxl-first {
		order: -1 !important;
	}

	.order-xxl-0 {
		order: 0 !important;
	}

	.order-xxl-1 {
		order: 1 !important;
	}

	.order-xxl-2 {
		order: 2 !important;
	}

	.order-xxl-3 {
		order: 3 !important;
	}

	.order-xxl-4 {
		order: 4 !important;
	}

	.order-xxl-5 {
		order: 5 !important;
	}

	.order-xxl-last {
		order: 6 !important;
	}

	.m-xxl-0 {
		margin: 0 !important;
	}

	.m-xxl-1 {
		margin: 0.25rem !important;
	}

	.m-xxl-2 {
		margin: 0.5rem !important;
	}

	.m-xxl-3 {
		margin: 1rem !important;
	}

	.m-xxl-4 {
		margin: 1.5rem !important;
	}

	.m-xxl-5 {
		margin: 3rem !important;
	}

	.m-xxl-auto {
		margin: auto !important;
	}

	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}

	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}

	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}

	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}

	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}

	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}

	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}

	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}

	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}

	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}

	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}

	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}

	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}

	.mt-xxl-0 {
		margin-top: 0 !important;
	}

	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}

	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}

	.mt-xxl-3 {
		margin-top: 1rem !important;
	}

	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}

	.mt-xxl-5 {
		margin-top: 3rem !important;
	}

	.mt-xxl-auto {
		margin-top: auto !important;
	}

	.me-xxl-0 {
		margin-right: 0 !important;
	}

	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}

	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}

	.me-xxl-3 {
		margin-right: 1rem !important;
	}

	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}

	.me-xxl-5 {
		margin-right: 3rem !important;
	}

	.me-xxl-auto {
		margin-right: auto !important;
	}

	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}

	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}

	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}

	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}

	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}

	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}

	.mb-xxl-auto {
		margin-bottom: auto !important;
	}

	.ms-xxl-0 {
		margin-left: 0 !important;
	}

	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}

	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}

	.ms-xxl-3 {
		margin-left: 1rem !important;
	}

	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}

	.ms-xxl-5 {
		margin-left: 3rem !important;
	}

	.ms-xxl-auto {
		margin-left: auto !important;
	}

	.p-xxl-0 {
		padding: 0 !important;
	}

	.p-xxl-1 {
		padding: 0.25rem !important;
	}

	.p-xxl-2 {
		padding: 0.5rem !important;
	}

	.p-xxl-3 {
		padding: 1rem !important;
	}

	.p-xxl-4 {
		padding: 1.5rem !important;
	}

	.p-xxl-5 {
		padding: 3rem !important;
	}

	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}

	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}

	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}

	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}

	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}

	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}

	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}

	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}

	.pt-xxl-0 {
		padding-top: 0 !important;
	}

	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}

	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}

	.pt-xxl-3 {
		padding-top: 1rem !important;
	}

	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}

	.pt-xxl-5 {
		padding-top: 3rem !important;
	}

	.pe-xxl-0 {
		padding-right: 0 !important;
	}

	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}

	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}

	.pe-xxl-3 {
		padding-right: 1rem !important;
	}

	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}

	.pe-xxl-5 {
		padding-right: 3rem !important;
	}

	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}

	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}

	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}

	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}

	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}

	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}

	.ps-xxl-0 {
		padding-left: 0 !important;
	}

	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}

	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}

	.ps-xxl-3 {
		padding-left: 1rem !important;
	}

	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}

	.ps-xxl-5 {
		padding-left: 3rem !important;
	}

	.text-xxl-start {
		text-align: left !important;
	}

	.text-xxl-end {
		text-align: right !important;
	}

	.text-xxl-center {
		text-align: center !important;
	}
}
@media (min-width: 1200px) {
	.fs-1 {
		font-size: 2.5rem !important;
	}

	.fs-2 {
		font-size: 2rem !important;
	}

	.fs-3 {
		font-size: 1.6875rem !important;
	}
}
@media print {
	.d-print-inline {
		display: inline !important;
	}

	.d-print-inline-block {
		display: inline-block !important;
	}

	.d-print-block {
		display: block !important;
	}

	.d-print-grid {
		display: grid !important;
	}

	.d-print-table {
		display: table !important;
	}

	.d-print-table-row {
		display: table-row !important;
	}

	.d-print-table-cell {
		display: table-cell !important;
	}

	.d-print-flex {
		display: flex !important;
	}

	.d-print-inline-flex {
		display: inline-flex !important;
	}

	.d-print-none {
		display: none !important;
	}
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.alert {
	color: #262626 !important;
}
.alert .btn-close {
	background-size: 9px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	height: 100%;
}

.btn {
	color: white !important;
}
.btn-link {
	color: #1c99ff !important;
}
.btn-light {
	color: #262626 !important;
}
.btn:disabled,
.btn.disabled {
	background-color: #f0f8fd !important;
	color: var(--bs-pewter-blue) !important;
	border-color: var(--bs-pewter-blue) !important;
}
.btn-primary:hover {
	background-color: #0430b9 !important;
}
.btn-secondary:hover {
	background-color: #1cb7ff !important;
}

.form-check .form-check-input {
	margin-right: 1.3125rem;
}
.form-check .form-check-input:checked[type="radio"],
.form-check .form-check-input:checked[type="checkbox"] {
	background-size: 70%;
	background-color: #1c99ff;
}
.form-check .form-check-input[type="checkbox"]:indeterminate {
	background-size: 50%;
}

.form-switch .form-check-input {
	background-size: 1.75rem;
	background-position: left -4px center;
	background-color: #cbcbcb;
}
.form-switch .form-check-input:checked {
	background-position: right -4px center;
}
.form-switch .form-check-input:focus {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.nav-tabs .nav-link {
	border-bottom-width: 6px;
	border-left-width: 0;
	border-right-width: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #fff #fff #043087;
}

.nav-link.active,
.nav-item.show .nav-link {
	border-bottom-width: 6px;
	border-left-width: 0;
	border-right-width: 0;
	font-weight: 600;
}
.nav-link.active:hover,
.nav-item.show .nav-link:hover {
	border-bottom-color: #043087;
}

.table-striped > tbody > tr > * {
	border-bottom-width: 0;
}

.form-floating > label {
	color: #809fb8;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	color: #809fb8;
	opacity: 1;
}

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 14px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	margin: 0;
	padding: 0;
}

body {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: #262626;
	background-color: #f3f6fa;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-top: 0;
	margin-bottom: 0;
}

p {
	margin-top: 0;
}

ul,
ol,
dl {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

a,
button,
input,
select {
	transition-property: transform, background, background-color, border-color,
		color;
	transition-duration: 0.3s;
}

textarea {
	resize: vertical;
}

a {
	color: #262626;
	text-decoration: none;
}
a:hover {
	color: #043087;
	text-decoration: underline;
}
a:focus {
	outline: none;
}

img {
	vertical-align: middle;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

[role="button"] {
	cursor: pointer;
}

[hidden] {
	display: none !important;
}

hr {
	color: #809fb8;
}

table {
	font-size: 0.875rem;
}
table tr th {
	white-space: nowrap;
}
table tr td {
	vertical-align: middle;
}

.btn {
	font-weight: 500;
}
.btn--small {
	padding: 0.75rem 1.5rem;
	border-radius: 0.625rem;
}
.btn--xs {
	width: 2.25rem;
	height: 2.25rem;
	padding: 0;
	border-radius: 0.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn--xs i {
	font-size: 1.25rem;
}
.btn--icon {
	padding: 0.675rem;
	justify-content: center;
	align-items: center;
	border-radius: 1rem;
	line-height: 100%;
}
.btn--icon [class^="ico-"],
.btn--icon [class*=" ico-"] {
	font-size: 1.5rem;
	line-height: 100%;
}
.btn-outline-pewter-blue {
	color: var(--bs-pewter-blue) !important;
}
.btn-outline-pewter-blue:hover {
	color: white !important;
	text-decoration: none;
}
.btn--outline {
	border-color: #e6ecf1;
}
.btn--outline > * {
	color: #e6ecf1;
}
.btn--outline:hover {
	text-decoration: none;
	border-color: #1c99ff;
}
.btn--pewter-blue {
	color: #809fb8 !important;
}
.btn--pewter-blue > * {
	color: #809fb8 !important;
}
.btn--gray {
	background-color: #f7f8fa;
	border: solid 1px #bfcfdb;
}
.btn-informativo {
	background-color: rgba(100, 100, 100, 0);
	border-color: rgba(100, 100, 100, 0);
	color: #043087;
}
.btn-perfil {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: #000 !important;
}
.btn:focus {
	box-shadow: none !important;
}
.btn:hover {
	text-decoration: none;
}
.btn[disabled] {
	cursor: default;
	pointer-events: none;
	opacity: 0.36;
}
table .btn:hover {
	border-color: #1c99ff !important;
}
table .btn:hover i {
	color: #1c99ff !important;
}
.dropdown-item:hover {
	text-decoration: none;
}

.login {
	background-image: url("../images/bg-login.jpg");
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: cover;
}
.login .card--login h5,
.login .card--login .h5 {
	font-size: 1.5rem;
	font-weight: normal;
}

.launch {
	position: relative;
	padding-top: 5.625rem;
}
.launch::before {
	content: "";
	width: 280px;
	height: 20px;
	background-color: #fc5000;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
}
.launch a {
	display: flex;
}
.launch h1,
.launch .h1 {
	line-height: 100%;
	font-size: 48px;
	font-weight: 900;
	letter-spacing: -3px;
}
@media only screen and (max-width: 768px) {
	.launch h1,
	.launch .h1 {
		font-size: 40px;
	}
}
.launch span {
	line-height: 100%;
}

@font-face {
	font-family: "icosicns";
	src: url("../fonts/icosicns/icosicns.eot?6gvqs9");
	src: url("../fonts/icosicns/icosicns.eot?6gvqs9#iefix")
			format("embedded-opentype"),
		url("../fonts/icosicns/icosicns.ttf?6gvqs9") format("truetype"),
		url("../fonts/icosicns/icosicns.woff?6gvqs9") format("woff"),
		url("../fonts/icosicns/icosicns.svg?6gvqs9#icosicns") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
[class^="ico-"],
[class*=" ico-"] {
	font-family: "icosicns" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ico-burger-close:before {
	content: "\e93b";
}
.ico-info-circle:before {
	content: "\e93a";
}
.ico-time:before {
	content: "\e938";
}
.ico-doc-empty:before {
	content: "\e939";
}
.ico-file-excel:before {
	content: "\e937";
}
.ico-graph-bar:before {
	content: "\e936";
}
.ico-enter:before {
	content: "\e935";
}
.ico-add-alarm:before {
	content: "\e934";
}
.ico-plus:before {
	content: "\e933";
}
.ico-auth:before {
	content: "\e932";
}
.ico-upload:before,
.file-upload:before {
	content: "\e931";
}

.ico-doc-pdf:before {
	content: "\e92e";
}

.ico-timer:before {
	content: "\e930";
}

.ico-sort:before {
	content: "\e92f";
}

.ico-increase:before {
	content: "\e92c";
}

.ico-decrement:before {
	content: "\e92d";
}

.ico-segment:before {
	content: "\e929";
}

.ico-alarm:before {
	content: "\e92a";
}

.ico-backup:before {
	content: "\e92b";
}

.ico-burger:before {
	content: "\e928";
}

.ico-exit:before {
	content: "\e90d";
}

.ico-list:before {
	content: "\e90e";
}

.ico-layout:before {
	content: "\e90f";
}

.ico-metrics:before {
	content: "\e910";
}

.ico-doc-word:before {
	content: "\e911";
}

.ico-home:before {
	content: "\e912";
}

.ico-progress:before {
	content: "\e913";
}

.ico-graphics:before {
	content: "\e914";
}

.ico-user-next:before {
	content: "\e915";
}

.ico-attach:before {
	content: "\e916";
}

.ico-search:before {
	content: "\e917";
}

.ico-bell:before {
	content: "\e918";
}

.ico-close:before,
.react-datepicker__input-container .react-datepicker__close-icon:before {
	content: "\e919";
}

.ico-doc-search:before {
	content: "\e91a";
}

.ico-user:before {
	content: "\e91b";
}

.ico-user-delete:before {
	content: "\e91c";
}

.ico-user-add:before {
	content: "\e91d";
}

.ico-calendar:before {
	content: "\e91e";
}

.ico-doc-edit:before {
	content: "\e91f";
}

.ico-doc-d:before {
	content: "\e920";
}

.ico-doc-x:before {
	content: "\e921";
}

.ico-chevron-up:before {
	content: "\e922";
}

.ico-chevron-down:before {
	content: "\e923";
}

.ico-chevron-right:before {
	content: "\e924";
}

.ico-chevron-left:before {
	content: "\e925";
}

.ico-less:before {
	content: "\e926";
}

.ico-check:before {
	content: "\e927";
}

.ico-block:before {
	content: "\e900";
}

.ico-analytics:before {
	content: "\e901";
}

.ico-settings:before {
	content: "\e902";
}

.ico-reports:before {
	content: "\e903";
}

.ico-notify:before {
	content: "\e904";
}

.ico-alarms:before {
	content: "\e905";
}

.ico-gestion:before {
	content: "\e906";
}

.ico-dashboard:before {
	content: "\e907";
}

.ico-graph:before {
	content: "\e908";
}

.ico-warning:before {
	content: "\e909";
}

.ico-face:before {
	content: "\e90a";
}

.ico-play:before {
	content: "\e90b";
}

.ico-house:before {
	content: "\e90c";
}

.sidebar {
	max-width: 20.875rem;
	width: 20.875rem;
	direction: ltr;
	background-color: #043087;
	color: white;
	transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
		margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	overflow: hidden;
	overflow-y: auto;
	min-height: 100vh;
	flex-basis: 20.875rem;
	justify-content: space-between;
}
.sidebar.collapsed {
	margin-left: -20.875rem;
}
@media screen and (max-width: 768px) {
	.sidebar {
		margin-left: -60rem;
		width: 60rem;
		max-width: 60rem;
		flex-basis: 60rem;
	}
	.sidebar.collapsed {
		margin-left: 0;
	}
}
.sidebar__header {
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}
.sidebar__header img {
	max-width: 184px;
}
.sidebar__header span {
	font-size: 0.9375rem;
	opacity: 0.39;
	font-weight: 300;
}
.sidebar__header span b {
	font-weight: 600;
}
.sidebar__sponsor {
	padding: 1.5rem;
	margin-block: auto;
}
.sidebar__sponsor a {
	display: inline-block;
}
.sidebar__sponsor img {
	max-width: 6.25rem;
	height: auto;
}
.sidebar__nav {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.9375rem;
	margin-top: 0;
	margin-bottom: auto;
}
.sidebar__footer {
	padding: 1.5rem;
	border-top: solid 1px #214895;
}
.sidebar::-webkit-scrollbar {
	width: 0.25rem;
	height: 0.25rem;
}
.sidebar::-webkit-scrollbar-track {
	background-color: #043087;
}
.sidebar::-webkit-scrollbar-thumb {
	background-color: #043087;
	outline: none;
}
.sidebar:hover::-webkit-scrollbar-track {
	background-color: #041f56;
}
.sidebar:hover::-webkit-scrollbar-thumb {
	background-color: #176bfb;
}

main {
	display: flex;
	width: 100%;
	min-width: 0;
	min-height: 100vh;
	transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
		margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
	overflow: hidden;
	flex-direction: column;
	background-color: #f3f6fa;
}

.wrapper {
	display: flex;
	width: 100%;
	height: 100vh;
}

.content {
	padding: 1.5625rem 0.8125rem;
	overflow: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	min-width: 320px;
	z-index: 1;
}
@media screen and (min-width: 768px) {
	.content {
		height: calc(100vh - 70px);
	}
}

.loading-container {
	display: flex !important;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1500;
}

.header {
	background-color: white;
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	position: sticky;
	align-items: center;
	gap: 1rem;
	color: #262626;
	z-index: 2;
}
.header__btn {
	font-size: 1rem;
	border: none;
	background-color: transparent;
	padding: 0;
}
.header__btn:hover {
	text-decoration: none;
	color: #043087;
}
@media screen and (max-width: 768px) {
	.header__btn [class^="ico-"]::before,
	.header__btn [class*=" ico-"]::before {
		content: "\e928";
	}
}
@media screen and (max-width: 768px) {
	.header__btn.active [class^="ico-"]::before,
	.header__btn.active [class*=" ico-"]::before {
		content: "\e925";
	}
}
.header h1,
.header .h1 {
	color: #262626;
	font-size: 1.75rem;
	line-height: 100%;
	margin-left: 0;
	margin-right: auto;
}
.header__tools {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.header__tools button {
	background-color: transparent;
	border: 0;
	padding: 0;
	margin: 0;
}
.header__tools button [class^="ico-"],
.header__tools button [class*=" ico-"] {
	font-size: 1.5rem;
	line-height: 100%;
}

.user {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
}
.user__ide {
	display: flex;
	flex-direction: column;
	line-height: 100%;
}
.user__ide span {
	line-height: 140%;
}

.user .avatar {
	min-width: 2.5rem;
	height: 2.5rem;
	border-radius: 2.5rem;
	overflow: hidden;
	position: relative;
}
.user .avatar img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
}

.nav {
	flex-direction: column;
	display: flex;
	gap: 1.5rem;
}
.nav__link {
	display: flex;
	align-items: center;
	gap: 1rem;
	color: #809fb8;
	text-decoration: none;
	font-size: 1rem;
	font-weight: 400;
	background-color: transparent;
	border: none;
	padding: 0;
}
.nav__link [class^="ico-"],
.nav__link [class*=" ico-"] {
	font-size: 1.25rem;
	min-width: 1.5rem;
}
.nav__link:hover {
	color: white;
	text-decoration: none;
}
.nav__link[aria-expanded="true"] {
	color: white;
	text-decoration: none;
}
.nav .collapse {
	transition: all 0.3s ease-in-out;
}

.subnav {
	display: flex;
	flex-direction: column;
	padding-left: 2.5rem;
	gap: 1rem;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
}
.subnav li {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
}
.subnav li::before {
	font-family: "icosicns";
	content: "\e924";
	color: #809fb8;
}
.subnav li a {
	color: #809fb8;
}
.subnav li a:hover {
	text-decoration: none;
	color: white;
}

.nav-tabs {
	display: flex;
	flex-direction: row;
}

.access {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 0.25rem 0.25rem #00000005;
	border-radius: 0.625rem;
	padding: 1.25rem;
	flex-wrap: wrap;
	gap: 0.4rem;
	height: 100%;
}
.access__info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 1.125rem;
	align-content: center;
}
.access__info > * {
	line-height: 100%;
}
.access__figure {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	width: 4.0625rem;
	height: 4.0625rem;
	flex-basis: 4.0625rem;
	border-radius: 1.125rem;
}
.access__figure [class^="ico-"],
.access__figure [class*=" ico-"] {
	font-weight: 100%;
	line-height: 100%;
	font-size: 1.75rem;
}
.access__progress {
	width: 100%;
}
.access--primary {
	background-color: #043087;
	color: white;
}
.access--primary [class^="ico-"],
.access--primary [class*=" ico-"] {
	color: #043087;
}
.access--secondary {
	background-color: #1c99ff;
	color: white;
}
.access--secondary [class^="ico-"],
.access--secondary [class*=" ico-"] {
	color: #1c99ff;
}
.access--tertiary {
	background-color: #fc5000;
	color: white;
}
.access--tertiary .access__info {
	max-width: 50%;
}
.access--tertiary [class^="ico-"],
.access--tertiary [class*=" ico-"] {
	color: #fc5000;
}
.access--gradient {
	background: #0610f9;
	background: linear-gradient(90deg, #0610f9 50%, #0088ff 100%);
	color: white;
}
.access:hover {
	text-decoration: none;
	cursor: pointer;
}

.meter {
	background-color: #043087;
	width: 100%;
	height: 12px;
	border-radius: 0.4375rem;
	display: block;
	position: relative;
}
.meter__bar {
	background-color: #acd8fe;
	position: absolute;
	height: 100%;
	left: 0;
	width: 0;
	border-radius: 0.4375rem;
	transition: width 0.3s ease-in-out;
}

.card {
	border: none;
}
.card__header {
	border-bottom: solid 1px #e6ecf1;
	padding-bottom: 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.card__header h2,
.card__header .h2 {
	font-size: 1.125rem;
}
.card__header .btn--icon {
	width: 3rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}
.card__body {
	position: relative;
}

.circle-chart {
	margin-inline: auto;
	display: block;
}

.circle-chart__circle {
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

.circle-chart__circle--negative {
	transform: rotate(-90deg) scale(1, -1);
}

@keyframes circle-chart-fill {
	to {
		stroke-dasharray: 0 100;
	}
}
text[font-weight="bolder"] {
	font-weight: 600;
}

.legend {
	gap: 0.75rem;
	color: #809fb8;
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.legend__dot {
	width: 1rem;
	height: 1rem;
	border-radius: 1rem;
	display: block;
	border: solid 3px transparent;
}
.legend__label {
	font-weight: 500;
	font-size: 1rem;
	white-space: nowrap;
}
.legend__value {
	font-weight: 600;
}
.legend__indicator {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.legend__indicator [class^="ico-"],
.legend__indicator [class*=" ico-"] {
	font-size: 9px;
}
.legend__item {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
}
.legend__item--danger .legend__value {
	color: #fc3d3d;
}
.legend__item--danger .legend__indicator {
	background-color: #fbd7d8;
}
.legend__item--danger .legend__indicator > * {
	color: #fc3d3d;
}
.legend__item--danger .legend__dot {
	background-color: #fc3d3d;
	border-color: #fbd7d8;
}
.legend__item--success .legend__value {
	color: #49b461;
}
.legend__item--success .legend__indicator {
	background-color: #d9f3df;
}
.legend__item--success .legend__indicator > * {
	color: #49b461;
}
.legend__item--success .legend__dot {
	background-color: #49b461;
	border-color: #d9f3df;
}
.legend-list {
	gap: 0 !important;
}
.legend-list .legend__item {
	border-bottom: solid 1px #e6efff;
	padding: 0.625rem;
}

.apexcharts-datalabel-value {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
}

.apexcharts-datalabels-group {
	transform: translateY(-6px);
}
.chip {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: solid 1px #e6ecf1;
	background-color: white;
	border-radius: 0.625rem;
	padding: 0.625rem;
	height: 100%;
	flex-wrap: wrap;
	gap: 1rem;
}
.chip__info {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	flex-basis: 20%;
	align-self: flex-start;
}
.chip__label {
	font-size: 0.75rem;
	color: #707070;
	line-height: 100%;
	text-align: right;
	white-space: nowrap;
}
.text-start .chip__label {
	text-align: left;
}
@media screen and (min-width: 1000px) and (max-width: 1285px) {
	.chip__label {
		text-align: left;
	}
}
.chip__value {
	font-size: 1.5rem;
	line-height: 100%;
	font-weight: 600;
	color: #809fb8;
}
.chip__figure {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
}
.chip__figure [class^="ico-"],
.chip__figure [class*=" ico-"] {
	font-size: 2rem;
}
.chip__figure--danger {
	color: #fc3d3d;
	background-color: #ffe6e6;
}
.chip__figure--success {
	color: #49b461;
	background-color: #e7f5ea;
}
.chip__figure--warning {
	color: #fd8d0e;
	background-color: #fff0df;
}
.chip__figure--info {
	color: #043087;
	background-color: #e1f2ff;
}
.chip__figure--default {
	color: #7f9fb8;
	background-color: #e6eff5;
}
.chip__status {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex-basis: 70%;
}
.chip__status b {
	font-size: 1.125rem;
	color: #809fb8;
}
@media screen and (min-width: 1000px) and (max-width: 1285px) {
	.chip__status {
		align-items: flex-start;
	}
}
.chip__title {
	color: #043087;
}
.chip--standar {
	background-color: #fbfcfc;
}
.chip.handle {
	transition: box-shadow 0.3s ease-in-out;
}
.chip.handle:hover {
	box-shadow: 0px 3px 6px #00000029;
}
.chip.active {
	border: solid 1px #809fb8;
}
@media screen and (min-width: 1000px) and (max-width: 1285px) {
	.chip {
		flex-direction: column;
		gap: 0.25rem;
		align-items: flex-start;
	}
}

.link {
	font-size: 0.875rem;
	background-color: transparent;
	border: none;
}
.link--icon {
	display: flex;
	align-items: center;
	line-height: 100%;
	color: #110f24;
}
.link--icon [class^="ico-"],
.link--icon [class*=" ico-"] {
	font-size: 20px;
	line-height: 100%;
	color: #809fb8;
}
.link--border {
	border: solid 1px #d9e1e7;
	border-radius: 0.625rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0.375rem;
}
.link--indicator {
	border: solid 1px #bfcfdb;
	border-radius: 0.625rem;
	padding: 0.375rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #809fb8;
}
.link--indicator i {
	color: #809fb8;
}
.link--indicator:hover {
	text-decoration: none;
}
.link-primary {
	transition: color 0.3s ease-in-out;
}
.link-primary:hover {
	text-decoration: underline;
	color: #1c99ff;
}
.link-primary:hover span,
.link-primary:hover i {
	color: #1c99ff;
}
.link:hover {
	text-decoration: none;
	color: #043087;
}
.link[disabled] {
	cursor: default;
	pointer-events: none;
	opacity: 0.36;
}
table .link:hover {
	border-color: #1c99ff !important;
}
table .link:hover i {
	color: #1c99ff !important;
}

.breadcrumbs {
	font-size: 0.875rem;
}
.breadcrumbs ul {
	display: flex;
	gap: 6px;
	align-items: center;
}
.breadcrumbs li {
	display: flex;
	align-items: center;
	line-height: 100%;
}
.breadcrumbs li::after {
	font-family: "icosicns";
	content: "\e924";
	line-height: 100%;
	font-size: 1rem;
	color: #809fb8;
}
.breadcrumbs li:last-child::after {
	display: none;
}
.breadcrumbs li:last-child a {
	pointer-events: none;
}
.breadcrumbs a {
	color: #809fb8;
	line-height: 100%;
}
.breadcrumbs a:hover {
	text-decoration: none;
	color: #043087;
}

.pagination {
	align-items: center;
}
.pagination .page-item {
	height: 100%;
}
.pagination .page-item .page-link {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	color: var(--bs-pewter-blue);
	padding-top: 0;
	padding-bottom: 0;
}
.pagination .page-item .page-link:hover {
	text-decoration: none;
	background-color: transparent;
	color: #176bfb;
}
.pagination .page-item .page-link:focus {
	box-shadow: none;
	background-color: transparent;
}
.pagination .page-item.active .page-link {
	background-color: #176bfb;
	color: white !important;
	font-weight: 600;
	border-radius: 0.25rem;
}

.exports {
	display: flex;
	justify-content: center;
	justify-content: flex-end;
	gap: 1rem;
	align-self: flex-start;
}
.exports .btn {
	min-height: 48px;
	min-width: 48px;
}
.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 0;
}
.form input {
	border-color: rgba(128, 159, 184, 0.3);
	color: #809fb8;
}
.form input::placeholder {
	color: #809fb8;
}
.form .form-control {
	height: 100%;
	color: #809fb8;
	max-height: calc(3.5rem + 2px);
}
.form .form-control:focus {
	box-shadow: none !important;
}
.form .form-control:disabled,
.form .form-control[readonly] {
	color: #bfcedb;
}
.form .form-control:disabled + label,
.form .form-control[readonly] + label {
	color: #bfcedb;
}
.form .form-floating .form-control + label {
	white-space: nowrap;
}
.form--search .form__field {
	width: 100%;
}
@media (min-width: 1200px) {
	.form--search .form__field {
		width: auto;
	}
}
.form--search .form-control {
	height: 3.25rem;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjAzMDYgMTQuNjE3NkwyMC4zMTM2IDE4LjkwMDZMMTguODk5NiAyMC4zMTQ2TDE0LjYxNzYgMTYuMDMxNkMxMi44MjA0IDE3LjQ2NzMgMTAuNTQxNyAxOC4xNjA1IDguMjQ5NDQgMTcuOTY4N0M1Ljk1NzE4IDE3Ljc3NjggMy44MjUzOCAxNi43MTQ2IDIuMjkxODUgMTUuMDAwMUMwLjc1ODMyMiAxMy4yODU2IC0wLjA2MDUyMTggMTEuMDQ4OSAwLjAwMzQ4ODgzIDguNzQ5NTZDMC4wNjc0OTk1IDYuNDUwMTggMS4wMDk1MSA0LjI2MjU4IDIuNjM2MDQgMi42MzYwNEM0LjI2MjU4IDEuMDA5NTEgNi40NTAxOCAwLjA2NzQ5OTUgOC43NDk1NiAwLjAwMzQ4ODgzQzExLjA0ODkgLTAuMDYwNTIxOCAxMy4yODU2IDAuNzU4MzIyIDE1LjAwMDEgMi4yOTE4NUMxNi43MTQ2IDMuODI1MzggMTcuNzc2OCA1Ljk1NzE4IDE3Ljk2ODcgOC4yNDk0NEMxOC4xNjA1IDEwLjU0MTcgMTcuNDY3MyAxMi44MjA0IDE2LjAzMTYgMTQuNjE3NkgxNi4wMzA2Wk0xNC4wMjQ2IDEzLjg3NTZDMTUuMzEwOSAxMi41NDk3IDE2LjAyMDkgMTAuNzY5MSAxNS45OTk5IDguOTIxODRDMTUuOTc4OSA3LjA3NDYzIDE1LjIyODYgNS4zMTA2MSAxMy45MTI1IDQuMDE0MjlDMTIuNTk2NCAyLjcxNzk2IDEwLjgyMTIgMS45OTQ0NCA4Ljk3Mzg3IDIuMDAxNDRDNy4xMjY1NiAyLjAwODQ0IDUuMzU2OSAyLjc0NTM5IDQuMDUwNjQgNC4wNTE2NEMyLjc0NDM5IDUuMzU3OSAyLjAwNzQ0IDcuMTI3NTYgMi4wMDA0NCA4Ljk3NDg3QzEuOTkzNDQgMTAuODIyMiAyLjcxNjk2IDEyLjU5NzQgNC4wMTMyOSAxMy45MTM1QzUuMzA5NjEgMTUuMjI5NiA3LjA3MzYzIDE1Ljk3OTkgOC45MjA4NCAxNi4wMDA5QzEwLjc2ODEgMTYuMDIxOSAxMi41NDg3IDE1LjMxMTkgMTMuODc0NiAxNC4wMjU2TDE0LjAyNDYgMTMuODc1NlYxMy44NzU2WiIgZmlsbD0iIzgwOUZCOCIvPgo8L3N2Zz4K");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 0.875rem;
	padding-right: 2.5rem;
	width: 100%;
}
@media (min-width: 1200px) {
	.form--search .form-control {
		width: 390px;
	}
}
.form--search .btn {
	max-height: 3.25rem;
}
@media (max-width: 767.98px) {
	.form.flex-wrap > * {
		width: 100%;
	}
}

input[type="file"] {
	border-radius: 0.625rem;
	font-size: 1rem;
}

input[type="checkbox"] {
	accent-color: #043087;
	width: 0.875rem;
	height: 0.875rem;
}
.message {
	padding: 0 2px;
	font-size: 0.75rem;
	line-height: 100%;
	color: #fc0000;
}

.form-check-input:focus {
	box-shadow: none !important;
}

.form-switch .form-check-input {
	min-height: 1.5rem;
}

.file-upload {
	border: 1px solid #ced4da;
	position: relative;
	width: 100%;
	height: 48px;
	min-height: 48px;
	border-radius: 0.625rem;
	overflow: hidden;
	z-index: 0;
}
.file-upload:after {
	content: attr(data-text);
	font-size: 1rem;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	padding: 10px 15px;
	display: block;
	width: calc(100% - 40px);
	pointer-events: none;
	z-index: 2;
	height: 38px;
	line-height: 38px;
	color: #7794ac;
	border-radius: 0 0.625rem 0.625rem 0;
	font-weight: 300;
}
.file-upload:before {
	font-family: "icosicns" !important;
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	height: 48px;
	background: transparent;
	color: #7794ac;
	font-weight: 500;
	z-index: 3;
	font-size: 1rem;
	line-height: 48px;
	padding: 0 15px;
	text-transform: uppercase;
	pointer-events: none;
	border-radius: 0 5px 5px 0;
}
.file-upload input {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 4;
	height: 100%;
	margin: 0;
	padding: 0;
	display: block;
	cursor: pointer;
	width: 100%;
}

textarea.form-control {
	min-height: 130px;
	resize: none;
}
.input-grid {
	display: flex;
	gap: 1rem;
	flex-wrap: nowrap;
	justify-content: space-between;
}
.input-grid .form-floating {
	flex: 1;
}
.input-grid .form-control {
	width: 100%;
}
.tools {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	flex-direction: row;
}
.tools .form {
	width: 100%;
}
@media (min-width: 992px) {
	.tools .form {
		width: auto;
	}
}
@media (max-width: 767.98px) {
	.tools > * {
		width: 100%;
	}
}

.badge {
	text-transform: capitalize;
	border: none;
	display: inline-flex;
	align-items: center;
	gap: 0.5rem;
}
.table .badge {
	font-size: 0.75rem;
}
.badge.bg-sice {
	background-color: #d8a684 !important;
	color: white;
}
.badge.bg-sicns {
	background-color: #7f9fb8 !important;
	color: white;
}
.badge.bg-white {
	color: #809fb8;
}
.badge.bg-gray {
	background-color: #ececee !important;
	color: #94939d;
}
.table .badge {
	width: initial;
	min-width: 52px;
}
.skeleton .badge--monochromatic {
	background-color: rgba(119, 148, 172, 0.05);
	color: #7794ac;
	min-width: 50px;
	text-align: center;
	justify-content: center;
}
.badge--monochromatic.bg-primary {
	background-color: #e5eaf3 !important;
	color: #043087;
}
.badge--monochromatic.bg-secondary {
	background-color: #e3edfd !important;
	color: #1c99ff;
}
.badge--monochromatic.bg-success {
	background-color: #e8f4ed !important;
	color: #00b140;
}
.badge--monochromatic.bg-danger {
	background-color: #ffebeb !important;
	color: #fc0000;
}
.badge--monochromatic.bg-gray {
	background-color: rgba(119, 148, 172, 0.05) !important;
	color: #7794ac !important;
}
.badge--monochromatic.bg-principal {
	background-color: rgba(4, 48, 135, 0.1) !important;
	color: #043087;
}
.badge-special {
	text-align: left;
	line-height: 130%;
	font-weight: 400;
	padding: 0.5rem;
	color: #333;
}
.badge-special b {
	font-weight: 600;
	display: block;
	margin-bottom: 0.25rem;
}
.badge-special br {
	display: none;
}

.modal .modal-header {
	color: #809fb8;
	font-size: 1.125rem;
}
.modal .modal-header .btn-close {
	padding: 0.5rem;
	border: solid 1px #d9e1e7;
	border-radius: 0.625rem;
	background-color: #ffffff;
	background-image: none;
	margin: 0 0 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal .modal-header .btn-close::before {
	font-family: "icosicns";
	content: "\e919";
	font-size: 1.5rem;
	line-height: 100%;
	color: var(--bs-pewter-blue);
}
.modal .modal-header .btn-close:hover {
	border-color: #1c99ff;
}
.modal .modal-header .btn-close:focus {
	outline: none;
	box-shadow: none;
}
.modal .modal-footer {
	padding-top: 0;
	justify-content: center;
	gap: 1.375rem;
}
.modal--bg-gray .modal-content {
	background-color: #f3f6fa;
}
.modal-backdrop.show {
	opacity: 0.7;
	background-color: #043087;
}

.box-icon {
	min-width: 5rem;
	height: 5rem;
	border-radius: 1.125rem;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 100%;
}
.box-icon > [class^="ico-"],
.box-icon > [class*=" ico-"] {
	line-height: 100%;
	font-size: 3rem;
	color: white;
}

.table tr th {
	vertical-align: middle;
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.table tr th [class^="ico-"],
.table tr th [class*=" ico-"] {
	color: var(--bs-pewter-blue);
	font-size: 1.25rem;
	line-height: 100%;
	width: 20px;
	text-align: center;
}
.table tr th [class^="ico-"]::before,
.table tr th [class*=" ico-"]::before {
	vertical-align: bottom;
	line-height: 100%;
}
.table tr th.text-center > span.d-flex {
	justify-content: center;
}
.table tr.active td {
	color: #262626;
}
.table tr td {
	color: #707070;
}
.table tr td .link {
	border: solid 1px #d9e1e7;
	border-radius: 0.625rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0.375rem;
}
.table tr td.string {
	width: 350px;
}
.table tr td.notify .dot {
	background-color: #bfcfdb;
}
.table tr td.notify b {
	font-weight: 600;
}
.table.sticky thead tr th {
	background-color: #fff;
	position: sticky;
	top: 0;
	z-index: 999;
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table-responsive::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}
.table-responsive::-webkit-scrollbar-track {
	background-color: #fff;
}
.table-responsive::-webkit-scrollbar-thumb {
	background-color: #fff;
	outline: none;
}
.table-responsive:hover::-webkit-scrollbar-track {
	background-color: #f3f6fa;
}
.table-responsive:hover::-webkit-scrollbar-thumb {
	background-color: #9eb0bf;
}
.mw-50 {
	max-width: 50%;
}
.mw-60 {
	max-width: 60%;
}
.mw-70 {
	max-width: 70%;
}

.min-w-100 {
	min-width: 100% !important;
}
.min-w-inherit {
	min-width: inherit !important;
}
.form-floating .min-w-inherit.simple-select .selector__control,
.form-floating .min-w-inherit.simple-select.isMultiple .selector__control,
 .selector__control{
	max-width: inherit;
}

.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.block-ellipsis {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	height: 2.25rem;
	margin: 0 auto;
	font-size: 0.875rem;
	line-height: 1.25;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.brd-top {
	border-top: solid 1px #bfcfdb;
}
.brd-end {
	border-right: solid 1px #bfcfdb;
}
.brd-bottom {
	border-bottom: solid 1px #bfcfdb;
}

.handle:hover {
	cursor: pointer;
}

.visibility-hidden {
	visibility: hidden;
}
.text-bold {
	font-weight: 700;
}
.separate {
	display: flex;
	align-items: center;
}
.separate li {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #707070;
}
.separate li:first-child {
	padding-left: 0;
}
.separate li:last-child {
	border-right: none;
}
.profile {
	display: flex;
	align-items: center;
	gap: 1.875rem;
}
.profile__btn {
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.profile__btn:focus {
	outline: none;
}
.profile__ide {
	display: flex;
	flex-direction: column;
	color: #212130;
}
.profile__ide b {
	font-size: 1.125rem;
}
.profile__ide span {
	font-size: 1rem;
}
.profile .avatar {
	height: 80px;
	width: 80px;
	border-radius: 30px;
	display: flex;
	overflow: hidden;
	position: relative;
}
.profile .avatar > img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
	z-index: 1;
	position: relative;
}
.profile .ico-photo {
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translate3d(25%, 25%, 0);
	z-index: 2;
}
.avatarIniciales {
	display: flex;
	justify-content: center;
	align-items: center;
}
.br-sm {
	border-radius: 0.625rem;
}
.point {
	width: 1rem;
	height: 1rem;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.point.bg-success {
	background-color: #00b140;
}
.point.bg-danger {
	background-color: #fc0000;
}
.centered > * {
	justify-content: center;
}
.toast-container {
	z-index: 1500;
}
.fw-semibold {
	font-weight: 600 !important;
}
.doc {
	display: flex;
	gap: 0.5rem;
	align-items: flex-start;
}
.doc-icon {
	font-size: 2rem;
	color: #809fb8;
}
.doc-icon i {
	display: flex;
	justify-content: center;
	align-items: center;
}
.doc-info {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}
.doc-title {
	color: #262626;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 100%;
}
.doc-time {
	color: #809fb8;
	font-size: 0.75rem;
	line-height: 130%;
}
.doc .ico.ico-close:hover {
	cursor: pointer;
}
.avatar {
	width: 2.5rem;
	height: 2.5rem;
	overflow: hidden;
	position: relative;
}
.d-flex .avatar {
	min-width: 2.5rem;
}

.white-space-normal {
	white-space: normal;
}
.fg-999 {
	flex-grow: 999;
}
.side-by-side {
	display: flex;
	flex-direction: row;
}
@media screen and (min-width: 768px) {
	.h-md-full {
		height: 100%;
	}
}
.bg-white-smoke {
	background-color: #f3f6f8;
}
.list--def {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}
.list--def .list__item {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	font-size: 1rem;
	color: #7794ac;
	width: 100%;
	padding: 0.5rem;
	border-bottom: solid 1px #e8eef2;
}
.list--def .list__item b {
	font-weight: 500;
	flex-basis: 50%;
	text-align: right;
}
.list--def .list__item span {
	flex-basis: 50%;
}
.list--def .list__item:last-child {
	border-bottom: none;
	padding-bottom: 0;
}
.simple-select {
	min-width: 240px;
}
.form-floating .simple-select.w-100 .form-control {
	width: 100%;
}
.simple-select .selector__control {
	position: relative;
	width: 100%;
	z-index: 100;
	min-height: 48px;
	border-color: rgba(119, 148, 172, 0.3);
	border-radius: 0.625rem;
}
.simple-select .selector__control:hover {
	border-color: #8eccff !important;
}
.simple-select .selector__value-container {
	flex-wrap: nowrap;
}
.simple-select .selector__single-value {
	color: #7794ac;
}
.simple-select .selector__placeholder {
	color: #7794ac;
	font-size: 1rem;
	font-weight: 400;
	white-space: nowrap;
}
.simple-select .selector__dropdown-indicator svg {
	color: var(--bs-pewter-blue);
}
.simple-select .selector__clear-indicator {
	border-right: solid 1px rgba(128, 159, 184, 0.5);
}
.simple-select .selector__clear-indicator svg {
	color: var(--bs-pewter-blue);
}
.simple-select .selector__indicator-separator {
	display: none;
}
.simple-select .selector__indicator {
	padding: 0 0.5rem;
}
.simple-select .selector__menu {
	z-index: 999;
	position: absolute;
}
.simple-select .selector__menu .selector__option {
	position: relative;
	color: var(--bs-pewter-blue);
}
.simple-select .selector__menu .selector__option.selector__option--is-selected {
	background-color: #ffffff;
	color: #889fb7;
}
.simple-select
	.selector__menu
	.selector__option.selector__option--is-selected:hover {
	background-color: #176bfb;
	color: white;
}
.simple-select .selector__menu .selector__option .form-check-input {
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}
.simple-select
	.selector__menu
	.selector__menu-list--is-multi
	.selector__option
	.form-check-input {
	visibility: visible;
	position: relative;
	top: inherit;
	left: inherit;
}
.simple-select .selector__multi-value {
	background-color: #fafbfd;
}
.simple-select .selector__multi-value__label {
	color: #809fb8;
	max-width: 100%;
}
.simple-select .selector__multi-value__remove {
	color: #809fb8;
}
.simple-select.is-invalid .selector__control {
	border-color: #fc0000;
}

.form-floating .simple-select > label {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 10px;
	color: #86a3ba;
	font-size: 0.6875rem;
	background-color: #fff;
	line-height: 100%;
	padding: 0.25rem 0.5rem;
	transform: translateY(-50%);
}
.form-floating .simple-select .selector__control {
	position: relative;
	z-index: 1;
}
.form-floating .simple-select.isMultiple .selector__control {
	max-width: 240px;
	z-index: 1;
}
.form-floating .simple-select .form-control {
	width: 240px;
}
.form-floating .simple-select .form-control.is-invalid {
	padding-top: 0;
	padding-bottom: 0;
}
.form-floating .simple-select .form-control.is-invalid .selector__control {
	border-color: transparent;
}
.form-floating
	.simple-select
	.form-control.is-invalid
	.selector__control--is-focused {
	border-color: transparent !important;
	box-shadow: none;
}
.form-floating
	.simple-select
	.form-control.is-invalid
	.selector__control--menu-is-open {
	border-color: transparent !important;
	box-shadow: none;
}
.form-floating
	.simple-select
	.form-control.is-invalid
	.selector__control:hover {
	border-color: transparent !important;
	box-shadow: none;
}
.form-floating
	.simple-select
	.form-control.is-invalid
	.selector__input-container {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 1px;
	margin-bottom: 1px;
}
.form-floating .simple-select .selector__option {
	display: flex;
	gap: 0.5rem;
	vertical-align: middle;
}
.form-floating .simple-select .selector__option label {
	position: relative;
	top: inherit;
	transform: inherit;
	left: inherit;
	padding: 0;
}
.form-floating .simple-select .selector__multi-value__remove {
	background-color: #fafbfd;
}
.form-floating .form-control.file-upload.is-invalid:before {
	right: 1.25rem;
}
.form-floating > .form-select {
	padding-top: 0.625rem;
	min-width: 240px;
}
.form-floating > .form-select ~ label {
	padding: 0;
	height: auto;
	z-index: 2;
	top: 0;
	left: 10px;
	color: #86a3ba;
	font-size: 0.6875rem;
	background-color: #fff;
	line-height: 100%;
	padding: 0.25rem 0.5rem;
	transform: translateY(-50%);
}
.selector__value-container.selector__value-container--is-multi.selector__value-container--has-value {
	color: #7794ac;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}
.state {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	color: var(--bs-pewter-blue);
	font-size: 0.6875rem;
	white-space: nowrap;
}
td .state:hover {
	cursor: pointer;
}
.state__indice {
	background-color: #043087;
	color: white;
	width: 1.375rem;
	height: 1.375rem;
	border-radius: 1.375rem;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.state--bg-default {
	background-color: #ecf1f5;
	color: #809fb8;
	padding: 0.375rem 0.5625rem;
	border-radius: 0.625rem;
}

.steps {
	display: flex;
	flex-direction: column;
}
.steps__item {
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	flex-direction: row;
	margin-bottom: 0.875rem;
}
.steps__item.is-current .steps__icon,
.steps__item.is-ready .steps__icon {
	background-color: #1c4dad;
	color: #fff;
	border-color: #1c4dad;
}
.steps__item.is-current .steps__indicator::after,
.steps__item.is-ready .steps__indicator::after {
	background-color: #1c4dad;
}
.steps__item.is-current .steps__summary span,
.steps__item.is-ready .steps__summary span {
	align-self: center;
}
.steps__item.is-current .steps__icon {
	font-size: 0.875rem;
	font-weight: 800;
}
.steps__item.is-current .steps__indicator::after {
	background-color: #e5eaef;
}
.steps__item:last-child .steps__indicator::after {
	display: none;
}
.steps__indicator {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	flex-basis: 1.5rem;
	flex-grow: 1;
	height: 100%;
}
.steps__indicator::after {
	content: "";
	width: 2px;
	border-radius: 2px;
	height: 100%;
	display: block;
	flex-grow: 999;
	background-color: #e5eaef;
	min-height: 40px;
}
.steps__icon {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 1.5rem;
	background-color: #fff;
	border: solid 1px #879eb6;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 100%;
	text-align: center;
	color: #809fb8;
}
.steps__summary {
	flex-grow: 999;
	background-color: #fff;
	padding: 0.5rem 0.875rem;
	border-radius: 0.5rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(1, 1fr);
	gap: 0.625rem;
	color: #809fb8;
	font-size: 0.75rem;
	min-height: 70px;
	align-items: flex-start;
}
.steps__summary span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.steps__summary span b {
	margin-right: 0.25rem;
}
.steps__summary span:first-child {
	font-size: 0.9375rem;
}
.steps__summary span:nth-child(even) {
	justify-content: flex-end;
}

.filters {
	display: flex;
	align-items: center;
	gap: 1rem;
	flex-wrap: wrap;
}
@media screen and (max-width: 1200px) {
	.filters > div {
		min-width: 190px;
		flex-basis: 25%;
	}
	.filters > div .simple-select {
		min-width: inherit;
	}
}
@media screen and (max-width: 992px) {
	.filters > div {
		flex-basis: 48%;
	}
}
@media screen and (max-width: 768px) {
	.filters > div {
		flex-basis: 100%;
	}
}
.react-datepicker {
	border: 1px solid #d8dee3;
	font-family: "Poppins", sans-serif;
	color: #8292a7;
}
.datepicker-label {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 10px;
	color: #86a3ba;
	font-size: 0.6875rem;
	background-color: #fff;
	line-height: 100%;
	padding: 0.25rem 0.5rem;
	transform: translateY(-50%);
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	border-bottom-color: #d8dee3;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: #8292a7;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #8292a7;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: #d8dee3;
}

.react-datepicker__header {
	background-color: #f3f6fa;
	border-bottom: 1px solid #d9dee3;
}
.react-datepicker__header button {
	background-color: transparent;
	border: none;
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before {
	border-bottom-color: #f3f6fa;
}

.react-datepicker__input-container {
	position: relative;
	width: 100%;
	z-index: 100;
	min-height: 48px;
	border: solid 1px rgba(119, 148, 172, 0.3);
	border-radius: 0.625rem;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.react-datepicker__input-container .react-datepicker__input {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.5rem;
	color: #7794ac;
	justify-content: space-between;
}
.react-datepicker__input-container .react-datepicker__input .ico-chevron-down {
	font-size: 1.35rem;
}
.react-datepicker__input-container .react-datepicker__input label {
	margin-left: 0;
	margin-right: auto;
}
.react-datepicker__input-container .react-datepicker__close-icon {
	background: white;
	height: auto;
	top: 16px;
	right: 4px;
}
.react-datepicker__input-container .form-control {
	margin-left: 0;
	margin-right: auto;
}
/* .tools .form-floating{
	max-width: 200px;
} */
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #f3f6fa;
	border: 1px solid #d9dee3;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #e0ebfd;
	color: #869eb6;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: #e0ebfd;
	color: #869eb6;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	background-color: #e0ebfd;
	color: #869eb6;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: #e0ebfd;
	color: #869eb6;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
	width: 100%;
	border: 0;
	min-height: 46px;
}
.react-datepicker-wrapper
	.react-datepicker__input-container
	input.form-control {
	color: #7794ac;
}
.react-datepicker-wrapper
	.react-datepicker__input-container
	input.form-control:focus {
	box-shadow: none;
}
.react-datepicker-wrapper
	.react-datepicker__input-container
	input.form-control::placeholder {
	color: #7794ac;
}
.react-datepicker-popper {
	z-index: 999;
}
@media (min-width: 768px) {
	.react-datepicker-popper.twoMonths {
		min-width: 483px;
	}
}
.rangePicker.react-datepicker-wrapper .react-datepicker__input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.rangePicker.react-datepicker-wrapper
	.react-datepicker__input-container::before,
.rangePicker.react-datepicker-wrapper
	.react-datepicker__input-container::after {
	color: #809fb8;
}
.rangePicker.react-datepicker-wrapper
	.react-datepicker__input-container::before {
	font-family: "icosicns" !important;
	content: "\e91e";
	display: block;
	font-size: 1.25rem;
	margin-left: 10px;
}
.rangePicker.react-datepicker-wrapper
	.react-datepicker__input-container::after {
	font-size: 1.5rem;
	font-family: "icosicns" !important;
	content: "\e923";
	display: block;
	margin-right: 5px;
}
.rangePicker.react-datepicker-wrapper
	.react-datepicker__input-container
	input:focus {
	outline: none;
}
.react-datepicker__day--in-selecting-range {
	color: white !important;
}
.react-datepicker__day--range-end {
	color: #8292a7 !important;
}

.react-datepicker__input-container .form-control.input-range {
	width: 216px;
	max-width: inherit;
}
.react-datepicker__tab-loop .react-datepicker-popper {
	z-index: 1056;
}
.rangePicker.react-datepicker-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
}
.info {
	display: flex;
	gap: 1.25rem;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	color: #809fb8;
	text-align: center;
}
.info__icon [class^="ico-"],
.info__icon [class*=" ico-"] {
	font-size: 2.5rem;
}
.info__title {
	font-size: 1.125rem;
	line-height: 1.5rem;
}
.info__text {
	font-size: 0.875rem;
	line-height: 1rem;
}
.modal-md .info {
	max-width: 220px;
	margin-left: auto;
	margin-right: auto;
}

.swiper .swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.75rem;
	margin-top: 0.5rem;
}
.swiper .swiper-pagination .swiper-pagination-bullet {
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.75rem;
	background-color: #d1dae5;
}
.swiper .swiper-pagination .swiper-pagination-bullet:hover {
	cursor: pointer;
}
.swiper
	.swiper-pagination
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #879eb6;
}

.status {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.875rem;
	color: #707070;
}
.dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.5rem;
}
.dot-md {
	width: 0.625rem;
	height: 0.625rem;
	border-radius: 0.625rem;
}
.dot.bg-primary {
	background-color: #043087;
}
.dot.bg-secondary {
	background-color: #1c99ff;
}
.dot.bg-success {
	background-color: #00b140;
}
.dot.bg-danger {
	background-color: #fc0000;
}
.dot.bg-warning {
	background-color: #fd8d0e;
}
.dot.c-01 {
	background-color: #ffff;
	border: solid 1px #bfcfdb;
}
.dot.c-02 {
	background-color: #e2b93b;
}
.dot.c-03 {
	background-color: #fc5000;
}
.dot.c-04 {
	background-color: #00b140;
}
.dot.c-05 {
	background-color: #fc0000;
}
.dot.c-06 {
	background-color: #043087;
}
.dot.c-07 {
	background-color: #905510;
}
.dot.c-08 {
	background-color: #ddef12;
}
.dot.c-09 {
	background-color: #cbfc64;
}
.dot.c-10 {
	background-color: #f67c1a;
}

.nav .nav-item .nav-link {
	display: flex;
	align-items: center;
	gap: 0.625rem;
}
.nav .nav-item .nav-link:hover {
	text-decoration: none;
	cursor: pointer;
}
.nav .nav-item .nav-link .badge {
	background-color: #bfcfdb;
}
.nav .nav-item .nav-link.active .badge {
	background-color: #043087;
}
.nav.tab-scrolling {
	gap: 1rem;
	flex-wrap: nowrap;
	overflow: hidden;
	overflow-x: auto;
	border-bottom: 0;
}
.nav.tab-scrolling:hover {
	cursor: grabbing;
}
.nav.tab-scrolling:hover::-webkit-scrollbar-thumb {
	background-color: #033087;
}
.nav.tab-scrolling:hover::before,
.nav.tab-scrolling:hover::after {
	opacity: 0.5;
}
.nav.tab-scrolling::-webkit-scrollbar {
	width: 1px;
	height: 1px;
}
.nav.tab-scrolling::-webkit-scrollbar-track {
	background-color: #dde2e5;
	box-shadow: none;
}
.nav.tab-scrolling::-webkit-scrollbar-thumb {
	background-color: #dde2e5;
}
.nav.tab-scrolling::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 6px;
	height: 47px;
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.25) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}
.nav.tab-scrolling::after {
	content: "";
	position: absolute;
	right: 0;
	top: 0;
	width: 6px;
	height: 47px;
	background: linear-gradient(
		270deg,
		rgba(0, 0, 0, 0.25) 0%,
		rgba(255, 255, 255, 0) 100%
	);
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.skeleton {
	width: 100%;
	font-size: 0.875rem;
	line-height: 100%;
}
.table-responsive .skeleton {
	min-width: 992px;
}
.skeleton thead tr th {
	text-align: center;
	padding: 0.5rem;
	border-bottom: solid 2px #7794ac;
	font-weight: 600;
}
.skeleton thead tr th:first-child {
	text-align: left;
}
.skeleton thead tr th:nth-child(2) {
	text-align: end;
	padding-right: 1.875rem;
}
.skeleton thead tr th:last-child {
	width: 110px;
}
.skeleton tbody tr td {
	text-align: center;
	padding: 0.75rem;
}
.skeleton tbody tr td:first-child {
	text-align: left;
	padding-left: 0;
}
.skeleton tbody tr td:nth-child(2) {
	text-align: end;
	padding-right: 1.875rem;
	width: 120px;
}
.skeleton tbody tr td:nth-child(3) {
	width: 120px;
}
.skeleton tbody tr td .btn-collapse {
	background-color: transparent;
	border: none;
	padding: 0;
}
.skeleton tbody tr td .btn-collapse [class^="ico-"],
.skeleton tbody tr td .btn-collapse [class*=" ico-"] {
	transition: transform 0.3s ease-in-out;
	font-size: 1.75rem;
	color: var(--bs-pewter-blue);
	transform: rotate(-90deg);
	display: flex;
	justify-content: center;
	align-items: center;
}
.skeleton tbody tr td .btn-collapse [class^="ico-"]::before,
.skeleton tbody tr td .btn-collapse [class*=" ico-"]::before {
	line-height: 100%;
}
.skeleton-level-2 td:first-child {
	padding-left: 2.5rem !important;
}
.skeleton-level-2 td .badge {
	background-color: #ecf7ef;
	color: #49b461;
}
.skeleton-level-3 td:first-child {
	padding-left: 5rem !important;
}
.skeleton-level-3 td .badge {
	background-color: #e7f5ff;
	color: #1c99ff;
}
.skeleton-level-4 td:first-child {
	padding-left: 7.5rem !important;
}
.skeleton-level-4-ponderacion td:first-child {
	padding-left: 7.5rem !important;
}
.skeleton-level-4-ponderacion td .badge {
	background-color: #ecf7ef;
	color: #49b461;
}
.skeleton-level-5 td .badge {
	background-color: #e7f5ff;
	color: #1c99ff;
}
.skeleton-level-5 td:first-child {
	padding-left: 10rem !important;
}
.skeleton-level-6 td .badge {
	background-color: rgba(119, 148, 172, 0.05);
	color: #7794ac;
}
.skeleton-level-6 td:first-child {
	padding-left: 12.5rem !important;
}
.skeleton-level-6-indicador td:first-child {
	padding-left: 12.5rem !important;
}
.skeleton-level-7 td .badge {
	background-color: rgba(119, 148, 172, 0.05);
	color: #7794ac;
}
.skeleton-level-7 td:first-child {
	padding-left: 14rem !important;
}
.skeleton-level-7-indicador td:first-child {
	padding-left: 14rem !important;
}
.skeleton-level-8 td .badge {
	background-color: rgba(119, 148, 172, 0.05);
	color: #7794ac;
}
.skeleton-level-8 td:first-child {
	padding-left: 11.25rem !important;
}
.skeleton-level-8-indicador td:first-child {
	padding-left: 11.25rem !important;
}
.skeleton .indicator {
	color: #7794ac;
}
.skeleton .children {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}
.skeleton .children table {
	width: 100%;
}
.skeleton .children.expanded {
	max-height: 100%;
}
.skeleton.alerts {
	min-width: 1080px;
}
.skeleton.alerts tr td:first-child {
	width: 600px;
}
.skeleton.alerts tr td:nth-child(2) {
	width: 120px;
}
.skeleton.alerts tr td:nth-child(2)[colspan="2"] {
	width: 240px;
}
.skeleton.alerts tr td:nth-child(3) {
	width: 120px;
}
.skeleton.alerts tr td:nth-child(4) {
	width: 110px;
}

.btn-collapse.rotate [class^="ico-"],
.btn-collapse.rotate [class*=" ico-"] {
	transform: rotate(0) !important;
}
.definition {
	font-size: 0.875rem;
}
.definition span {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1.25rem;
}
.definition span:nth-child(even) {
	background-color: #fafbfd;
}
.definition dt {
	font-weight: 400;
	flex-basis: 30%;
	flex-grow: 1;
	margin-bottom: 0;
}
.definition dd {
	font-weight: 600;
	color: #809fb8;
	flex-grow: 999;
	margin-bottom: 0;
}
.toast {
	border: none !important;
}
.toast-body {
	display: flex;
	align-items: center;
	gap: 1rem;
	background-color: #000000 !important;
	color: #fff !important;
	font-size: 1rem;
	border-radius: 0.25rem;
	justify-content: space-between;
	border: none !important;
}
.toast-body.success,
.toast-body.Success {
	border-left: solid 4px #00b140 !important;
}
.toast-body.info,
.toast-body.Info {
	border-left: solid 4px #1c99ff !important;
}
.toast-body.danger,
.toast-body.Danger {
	border-left: solid 4px #fc5000 !important;
}
.toast-body.warning,
.toast-body.Warning {
	border-left: solid 4px #e2b93b !important;
}
.toast__button {
	background-color: transparent;
	border: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
}
.toast__button i {
	color: #fff !important;
	font-size: 1.5rem;
}
.accordion-table .accordion-item {
	border: none;
}
.accordion-table .accordion-item .accordion-header {
	display: flex;
	align-items: center;
	border-bottom: solid 5px #7794ac;
}
.accordion-table .accordion-item .accordion-header .accordion-toggle {
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	gap: 0.25rem;
	padding: 0;
}
.accordion-table .accordion-item .accordion-header .accordion-toggle i {
	font-size: 1.25rem;
}
.accordion-item {
	background-color: transparent;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border: none;
	margin-bottom: 1.5rem;
}
.accordion-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.75rem 0;
	border-bottom: solid 1px #e8eef2;
}
.accordion-toggle {
	background-color: transparent;
	border: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.accordion-toggle i {
	font-size: 1.5rem;
	line-height: 100%;
	color: #809fb8;
}
.accordion-body {
	padding: 1rem 0;
}
.accordion-tab {
	display: flex;
	align-items: center;
	gap: 2rem;
}
.accordion-tab li {
	font-size: 0.865rem;
	color: #809fb8;
	border-bottom: solid 6px transparent;
	padding-left: 6px;
	padding-right: 6px;
}
.accordion-tab li:hover {
	cursor: pointer;
}
.accordion-tab li.active {
	font-weight: 600;
	color: #043087;
	border-bottom: solid 6px #043087;
}
.galeria {
	display: flex;
	gap: 1.125rem;
	padding: 0;
}
.galeria a {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.625rem;
	overflow: hidden;
	width: 5.25rem;
	height: 5.25rem;
	background-color: #7f9fb8;
}
.galeria a:hover {
	text-decoration: none;
}
.galeria a img {
	-o-object-fit: cover;
	object-fit: cover;
	width: 100%;
	height: 100%;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.galeria a.all {
	background-color: #f4f7f9;
	color: #809fb8;
	font-size: 0.875rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5.125rem;
	height: 5.125rem;
}
#chart {
	min-height: 400px;
	height: 100%;
}
@media (min-width: 1200px) {
	#chart {
		min-height: 250px;
		height: 100%;
	}
}

#char > div,
.card.h-100 > .card__body {
	height: 100%;
}

.d-flex.justify-content-between.flex-fill > .form-floating {
	width: 100%;
}

.cursor-pointer {
	cursor: pointer;
}

.ocultarItems {
	opacity: 0;
	position: absolute;
	bottom: 0;
}
.seleccionItems {
	display: inherit;
}
.dsac-datepicker .react-datepicker-wrapper .react-datepicker__input-container {
	padding: 0;
	border: 0;
	min-height: inherit;
}

.dsac-datepicker
	.react-datepicker-wrapper
	.react-datepicker__input-container
	.react-datepicker__input {
	position: relative;
	width: 100%;
	z-index: 100;
	min-height: 48px;
	border: solid 1px rgba(119, 148, 172, 0.3);
	border-radius: 0.625rem;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.dsac-datepicker
	.react-datepicker-wrapper
	.react-datepicker__input-container
	.react-datepicker__input.is-invalid {
	border-color: #fc0000;
	margin-bottom: 0.5rem;
}
.text-break {
	hyphens: auto;
}
.word-break-all {
	word-break: break-all;
}

.pswp img{
	height: auto!important;
}

.simple-select .selector__placeholder{
	display: inline-grid;
	display: -ms-inline-grid;
}

@media (max-width: 480px) {
	.form{
		width: 100%;
	}
	.simple-select,
	.form-floating .simple-select.isMultiple .selector__control{
		max-width: inherit;
		width: 100%;
	}
}

@media (max-width: 768px) {
  .apexcharts-xaxis-labels text {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
	#chart{
		min-height: 200px;
	}
	#chart.min-h-charts-mobile-400{
		min-height: 400px;
	}
}
